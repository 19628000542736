import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { UnitTypes } from '@common/enums';
import { CropService } from '@services/crop.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

// fuse.harvestDto with yields/metrics also indexed by grade name, for use in html
interface groupedCropResult {
  harvest: fuse.harvestDto;
  harvestId: number;
  yieldsByGrade: fuse.yieldDto[];
  metricsByGrade: fuse.yieldMetricDto[][];
  filterDates: (date: Date) => boolean;
}
export class CropCycleResultsDialogController extends BaseController {
  public cropCycle: fuse.siteCropCycle;
  public groupedCropCycleResults = [] as groupedCropResult[]; // grouped by date
  public metricsByName: {};
  public availableGrades: fuse.gradeDto[];
  public metrics: fuse.metricDto[];
  public yieldUnit: uomUnit;
  public pctUnits: uomUnit;
  public minDate: Date;
  public maxDate: Date;
  public formattedStart: string;
  public formattedEnd: string;
  public errorHtml: string = '';

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _cropService: CropService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private _errors = {};
  private _titleDateFormat = 'dd MMM yyyy';
  private _backupHarvests: fuse.harvestDto[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    CropService: CropService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    cropCycle: fuse.siteCropCycle,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._cropService = CropService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.scope['resultsForm'] = {};
    this.cropCycle = cropCycle;

    const localeToday = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);

    this.minDate = this.cropCycle.startDate;
    this.maxDate = localeToday < cropCycle.endDate ? localeToday : cropCycle.endDate.clone();

    this.formattedStart = this.cropCycle.startDate.toString(this._titleDateFormat);
    this.formattedEnd = this.cropCycle.endDate.toString(this._titleDateFormat);

    this.metrics = this.cropCycle.cropMetrics.metrics.filter((m) => !m.derived);
    this.metricsByName = {};
    this.metrics.forEach((metric) => {
      this.metricsByName[metric.name] = metric;
      metric['uom'] = this._unitOfMeasureService.getUnits(metric.unitBaseClass, metric.scaleId);
    });

    this.availableGrades = cropCycle.cropMetrics.grades;
    this.yieldUnit = new uomUnit(this.cropCycle.cropMetrics.unit);
    this.pctUnits = UnitOfMeasureService.getUnits(UnitTypes.Percent);

    this.backupInitial(this.cropCycle);

    if (!cropCycle.harvests?.length) {
      this.add(); // ensure at least one blank harvest date shown by default
    }

    this.groupCropCycleResults();
    this.recalcDerived();
  }

  private filterResultDates(excludedIndex: number): (date: Date) => boolean {
    return (date: Date) => {
      return this.groupedCropCycleResults
        .filter((x, i) => i !== excludedIndex)
        .every((a) => a.harvest.date?.getTime() !== date.getTime());
    };
  }

  private groupCropCycleResults() {
    this.groupedCropCycleResults = [] as groupedCropResult[];
    this.cropCycle.harvests.forEach((harvest) => {
      this.groupHarvestData(harvest);
    });
  }

  private backupInitial(cropCycle: fuse.siteCropCycle) {
    this._backupHarvests = structuredClone(this.cropCycle.harvests);
  }

  // this makes empty arrays with objects in groupedCropCycleResults - use this.cropcycle instead (the same data in the right format)
  private groupHarvestData(harvest: fuse.harvestDto) {
    const cycleResult = {
      harvest: harvest,
      yieldsByGrade: [] as fuse.yieldDto[],
      metricsByGrade: [] as fuse.yieldMetricDto[][],
      // The edited GroupedCropCycleResult's row must be excluded from the dates validation.
      filterDates: this.filterResultDates(this.groupedCropCycleResults.length)
    } as groupedCropResult;

    this.groupedCropCycleResults.push(cycleResult);

    this.availableGrades.forEach((grade) => {
      let yieldObj = harvest.yields?.find((y) => y.gradeId == grade.id) as fuse.yieldDto;

      if (!yieldObj) {
        yieldObj = {
          gradeId: grade.id,
          gradeName: grade.name,
          gradeSequence: grade.sequenceNumber,
          weight: null,
          metrics: [],
        } as fuse.yieldDto;
        harvest.yields.push(yieldObj);
      }

      cycleResult.yieldsByGrade[grade.name] = yieldObj;
      cycleResult.metricsByGrade[grade.name] = [];

      this.metrics?.forEach((metric) => {
        let metricData = yieldObj.metrics?.find((a) => a.metricId == metric.id);

        if (!metricData) {
          metricData = { metricId: metric.id } as fuse.yieldMetricDto;
          yieldObj.metrics.push(metricData);
        }

        cycleResult.metricsByGrade[grade.name][metric.name] = metricData;
      });
    });
  }

  public add() {
    const harvest = { yields: [] as fuse.yieldDto[] } as fuse.harvestDto;
    this.cropCycle.harvests.push(harvest);
    this.groupHarvestData(harvest);
  }

  public delete(cropCycleResult: groupedCropResult) {
    if (!cropCycleResult.harvest.date) {
      if (cropCycleResult.harvest.yields.every((y) => !y.weight && y.metrics.every((m) => !m.value))) {
        cropCycleResult.harvest.deleted = true;
        this.groupedCropCycleResults = this.groupedCropCycleResults.filter((a) => a != cropCycleResult);
        return;
      }
    }

    const confirm = this._languageService
      .confirm()
      .title('CROPS.CYCLES.DELETE_HARVEST')
      .htmlContent('CROPS.CYCLES.DELETE_HARVEST_TEXT')
      .ariaLabel('COMMON.REMOVE')
      .multiple(true)
      .ok('COMMON.REMOVE')
      .cancel('COMMON.CANCEL');

    this._languageService.show(confirm).then(() => {
      cropCycleResult.harvest.deleted = true;
      this.groupedCropCycleResults = this.groupedCropCycleResults.filter((a) => a != cropCycleResult);
    });
  }

  public checkYields() {
    this.recalcDerived();

    this._errors['yieldValue'] = null;
    this.cropCycle.harvests.forEach((harvest) => {
      harvest.yields.forEach((y) => {
        if (y.weight === undefined) { // NOTE: Value is 'undefined' when angular validation is not valid (i.e., `ng-min="0"` directive).
          this._errors['yieldValue'] = this._languageService.instant('COMMON.ERRORS.GREATER_OR_EQUAL', {
            name: this._languageService.instant('CROPS.CYCLES.YIELD'),
            min: 0,
          });
        }
      });
    });

    this.updateErrorContent();
  }

  public checMetric(e) {
    const metricIndex = e.target.getAttribute('metric');

    this.checkMetricErrors(metricIndex);
  }

  public save() {
    this.cropCycle.harvests.forEach(
      (harvest) => (harvest.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(harvest.date)),
    );

    this._http.post(CommonHelper.getApiUrl('crops/updateCropCycleResults'), this.cropCycle).then(
      (result) => {
        this._mdDialog.hide(true);
        this._languageService.success('COMMON.CHANGES_SAVED');
      },
      (err) => {
        this._languageService.error('COMMON.CHANGES_NOT_SAVED');
      },
    );
  }

  public cancel() {
    this.cropCycle.harvests = this._backupHarvests; // revert any changes
    this._mdDialog.hide();
  }

  private updateErrorContent() {
    this.errorHtml = '';

    Object.keys(this._errors).forEach((key) => {
      const error = this._errors[key];

      if (error) {
        this.errorHtml += error + '<br>';
      }
    });
  }

  private checkMetricErrors(metricIndex: number) {
    const metric = this.metrics[metricIndex];

    const updateErrors = (isValid: boolean): void => {
      if (!isValid) {
        const unit = metric['uom'] as uomUnit;
        const lower = !unit ? metric.lowerLimit : unit.fromBaseText(metric.lowerLimit);
        const upper = !unit ? metric.upperLimit : unit.fromBaseText(metric.upperLimit);

        const tag =
          metric.lowerLimit != null && metric.upperLimit != null
            ? 'RANGE'
            : metric.lowerLimit != null
              ? 'GREATER_OR_EQUAL'
              : 'LESS_OR_EQUAL';

        this._errors[metric.name] = this._languageService.instant(`COMMON.ERRORS.${tag}`, {
          name: this._languageService.instant(`DB_VALUES.CROP_METRICS.${metric.name}`),
          min: lower,
          max: upper,
        });
      } else {
        this._errors[metric.name] = null;
      }

      this.updateErrorContent();
    }

    let isMetricValid = true;

    for (let harvest = 0; harvest < this.cropCycle.harvests.length; harvest++) {
      if (this.cropCycle.harvests[harvest].deleted) {
        return;
      }

      for (let grade = 0; grade < this.availableGrades.length; grade++) {
        const tag = `metric_${metricIndex}_${harvest}_${grade}`;
        const metricElem = this.scope['resultsForm'][tag];

        metricElem.$validate();

        if (!metricElem.$valid) {
          isMetricValid = false;
        }
      }
    }

    updateErrors(isMetricValid);
  }

  private recalcDerived() {
    this._cropService.calcDerived(this.cropCycle);
  }
}

angular.module('app.crops').controller('CropCycleResultsDialogController', CropCycleResultsDialogController);
