import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { UnitTypes } from '@common/enums';
import { CropService, TargetLegend } from '@services/crop.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { AmGanttChart } from 'src/typings/amchart-gantt';
import { CropCyclesController } from '../cycles.controller';
import { BaseController } from 'src/app/base.controller';

class CropCyclesChartComponent implements angular.IComponentOptions {
  bindings = {
    changeFlag: '<', // bit of a hack to let us refresh graph, but works for now
    cropCycles: '<',
    minDate: '=',
    maxDate: '=',
    dayNumber: '=',
    parent: '=',
    legendData: '=',
    selectedMetric: '<',
  };
  controller = CropCyclesChartController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/crops/cycles/crop-cycles-chart/crop-cycles-chart.html';
}

interface ICropCycleItem {
  siteId: number;
  siteName: string;
  color: string;
  segments: ICropCycleSegment[];
}

interface ICropCycleSegment {
  start: string;
  end: string;
  cropCycleId: number;
  cropId: number;
  crop: string;
  cropMetricId: number;
  cropMetricName: string;
  color: string;
  metricName: string;
  metricValueWithUnit: string;
}

class CropCyclesChartController extends BaseController {
  public dayNumber: number;
  public currentCycle: fuse.siteCropCycle;
  public disableResult: boolean = false;
  public legendData: TargetLegend[];

  private _cropService: CropService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private mouseIn: boolean;
  private parent: CropCyclesController;
  private chart: AmCharts.AmSerialChart;
  private minDate: Date;
  private maxDate: Date;
  private cropCycles: EntityList<fuse.siteCropCycle>;
  private yieldUnit: uomUnit;
  private metricUnit: uomUnit;
  private selectedMetric: fuse.metricDto;

  constructor(
    $scope: angular.IScope,
    CropService: CropService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._cropService = CropService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.yieldUnit = this._unitOfMeasureService.getUnits(UnitTypes.YieldArea);
  }

  $onChanges(changes) {
    if (changes.cropCycles?.currentValue || changes.changeFlag?.currentValue) {
      this.displayChart();
    }
  }

  $onDestroy() {
    if (this.chart != null) {
      this.chart.clear();
      this.chart = null;
    }
  }

  private displayChart() {
    const dataProvider = [] as ICropCycleItem[];

    this.cropCycles.filterEntities().forEach((cropCycle) => {
      let cropCycleItem = dataProvider.find((a) => a.siteId == cropCycle.siteId);

      if (!cropCycleItem) {
        cropCycleItem = {
          siteId: cropCycle.siteId,
          siteName: cropCycle.siteName,
          segments: [],
        } as ICropCycleItem;

        dataProvider.push(cropCycleItem);
      }

      let colour = '#00AEFF';
      const status = cropCycle['metricStatus'];

      if (cropCycle.isDeleted) {
        colour = '#CEC9C7';
      } else if (status) {
        const statusTargetDisplay = this._cropService.targetDisplay[status];

        if (statusTargetDisplay?.color) {
          colour = statusTargetDisplay.color;
        }
      }

      let metricName: string;
      let metricValueWithUnit: string;

      if (!this.selectedMetric || this.selectedMetric?.name === 'NO_SELECTION') {
        // default
        metricName = this._languageService.instant('DB_VALUES.CROP_METRICS.Total Yield');
        metricValueWithUnit = this.yieldUnit.fromBaseText(cropCycle[this._cropService.yieldAreaTag]);
      } else {
        this.metricUnit = this._unitOfMeasureService.getUnits(this.selectedMetric.unitBaseClass, this.selectedMetric.scaleId);
        metricName = this._languageService.instant('DB_VALUES.CROP_METRICS.' + this.selectedMetric.name);
        metricValueWithUnit = cropCycle['metricValue'] ? this.metricUnit.fromBaseText(cropCycle['metricValue']) : '';
      }

      const segment = {
        start: cropCycle.startDate.toString('yyyy-MM-dd'),
        end: cropCycle.endDate.toString('yyyy-MM-dd'),
        cropCycleId: cropCycle.cropCycleId,
        cropId: cropCycle.crop.id,
        crop: cropCycle.crop.name,
        cropMetricId: cropCycle.cropMetrics.id,
        cropMetricName: cropCycle.cropMetrics.name,
        color: colour,
        metricName: metricName,
        metricValueWithUnit: metricValueWithUnit,
      } as ICropCycleSegment;
 
      cropCycleItem.segments.push(segment);
    });

    let baseHeight = 72;
    // No legend shown unless metric selected
    let legend = null;

    if (this.legendData) {
      legend = {
        position: 'top',
        data: this.legendData,
      };

      baseHeight += 46;
    }

    const chartOption = {
      type: 'gantt',
      theme: 'dark',
      marginRight: 70,
      period: 'DD',
      dataDateFormat: 'YYYY-MM-DD',
      columnWidth: 0.5,
      valueAxis: {
        type: 'date',
        minimumDate: this.minDate,
        maximumDate: this.maxDate,
      },
      brightnessStep: 7,
      graph: {
        fillAlphas: 1,
        lineAlpha: 1,
        lineColor: '#fff',
        balloonText:
          '<b>[[crop]]</b>:<br />[[open]] -- [[value]]<br />' +
          '[[metricName]]' +
          ': [[metricValueWithUnit]]',
      },
      rotate: true,
      categoryField: 'siteName',
      segmentsField: 'segments',
      colorField: 'color',
      startDateField: 'start',
      endDateField: 'end',
      dataProvider: dataProvider,
      valueScrollbar: {
        autoGridCount: false,
      },
      chartCursor: {
        cursorColor: '#55bb76',
        valueBalloonsEnabled: false,
        cursorAlpha: 0,
        zoomable: false,
      },
      zoomOutText: '',
      legend: legend,
    } as AmGanttChart;

    this.chart = AmCharts.makeChart('crop-cycles-chart', chartOption) as AmCharts.AmSerialChart;

    this.chart.addListener('clickGraphItem', (event) => {
      this.openContextMenuModal(event);
    });

    document.getElementById('crop-cycles-chart').style.height = baseHeight + dataProvider.length * 40 + 'px';
  }

  private openContextMenuModal(event: any) {
    const item = this.cropCycles.entities.find(
      (a) => a.siteName == event.item.category && a.startDate.getTime() == (event.item.values as any).open,
    );

    if (item.isDeleted) {
      return;
    }

    this.disableResult = item.startDayNumber > this.dayNumber;
    this.currentCycle = item;

    const contextMenu = document.getElementById('context-menu');
    const contextMenuEdit = document.getElementById('context-menu-edit');
    const contextMenuDelete = document.getElementById('context-menu-delete');
    const contextMenuResult = document.getElementById('context-menu-result');

    contextMenuEdit.onclick = () => this.parent.editCropCycle(item);
    contextMenuDelete.onclick = () => this.parent.deleteCropCycle(item);
    contextMenuResult.onclick = () => this.parent.editCropResult(item);

    // Can't do this in the .html, because this pop-up takes several seconds to load scope variables for some reason
    if (this.disableResult) {
      contextMenuResult.setAttribute('disabled', 'true');
    } else { 
      contextMenuResult.removeAttribute('disabled');
    }

    contextMenu.onmouseenter = () => {
      this.mouseIn = true;
    };

    contextMenu.onmouseleave = () => {
      this.mouseIn = false;

      setTimeout(() => {
        if (!this.mouseIn) {
          contextMenu.setAttribute('style', 'display: none');
        }
      }, 1000);
    };

    const chartContainer = document.getElementById('crop-cycles-chart-container');
    const contextMenuWidth = 318;
    const contextMenuHeight = 54;

    let top = event.event.layerY - chartContainer.scrollTop;

    if (top + contextMenuHeight > chartContainer.offsetHeight) {
      top = chartContainer.offsetHeight - contextMenuHeight;
    }

    let left = event.event.layerX;

    if (left + contextMenuWidth > chartContainer.offsetWidth) {
      left = chartContainer.offsetWidth - contextMenuWidth;
    }

    contextMenu.setAttribute('style', `top: ${top}px; left: ${left}px;`);
  }
}

angular.module('app.crops').component('cropCyclesChart', new CropCyclesChartComponent());
