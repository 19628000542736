import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { ApiService } from '@services/api.service';
import { EntityListService } from '@services/entity-list.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { EditPricesDialogController } from './editprices-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export class CropPricesController extends BaseController {
  public assetType = 'Crop Prices';
  public entityList: EntityList<fuse.cropPricesDto>;
  public cropMetricsList: EntityList<fuse.cropMetricsDto>;

  private _mdDialog: angular.material.IDialogService;
  private _apiService: ApiService;
  private _entityListService: EntityListService;
  private _languageService: LanguageService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    ApiService: ApiService,
    EntityListService: EntityListService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._apiService = ApiService;
    this._entityListService = EntityListService;
    this._languageService = LanguageService;
  }

  $onInit() {
    this._entityListService.getStatusTaggedEntityList(
      'crops/getCropMetrics',
      (result) => {
        this.cropMetricsList = result;
      },
      false,
    );
    this.reloadEntityList();
  }

  public reloadEntityList() {
    this._entityListService.getEntityList(
      'crops/getPrices',
      (result) => {
        this.entityList = result;
      },
      true,
      null,
      this.entityList,
    );
  }

  public editPrices(cm: fuse.cropPricesDto) {
    if (!this.apf.hasCropMetricsFull) return;
    this.pricesDialog(cm).then((res) => {
      if (res) {
        this.saveChanges(res as fuse.cropPricesDto);
      }
    });
  }

  public addNewPrices() {
    if (!this.apf.hasCropMetricsFull) return;

    this.pricesDialog(null).then((res) => {
      if (res) {
        this.createNew(res as fuse.cropPricesDto);
      }
    });
  }

  public pricesDialog(cropPrices: fuse.cropPricesDto) {
    const locals = {
      cropMetricsList: this.cropMetricsList,
      entityList: this.entityList,
      cropPrices: cropPrices,
    };
    return this._mdDialog.show({
      controller: EditPricesDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/crops/editprices-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: locals,
    });
  }

  public createNew(cropPrices: fuse.cropPricesDto) {
    if (!this.apf.hasCropMetricsFull) return;

    this._apiService.postHandleDupes(
      'crops/createCropPrices',
      cropPrices,
      (res) => {
        this._languageService.success('CROPS.CROP_PRICES_CREATED', 'COMMON.SUCCESS', { name: cropPrices.name });
        this.reloadEntityList();
      },
      this.assetType,
    );
  }

  public saveChanges(cropPrices: fuse.cropPricesDto) {
    if (!this.apf.hasCropMetricsFull) return;

    this._apiService.postHandleDupes(
      'crops/updateCropPrices',
      cropPrices,
      (res) => {
        this._languageService.success('CROPS.CROP_PRICES_SAVED', 'COMMON.SUCCESS', { name: cropPrices.name });
        this.reloadEntityList();
      },
      this.assetType,
    );
  }
}

angular.module('app.crops').controller('CropPricesController', CropPricesController);
