// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#crop-cycle-option md-checkbox {
  margin-bottom: 0;
}
#crop-cycle-option md-input-container {
  margin: 16px 0;
  padding: 0;
}
#crop-cycle-option md-input-container .md-errors-spacer {
  min-height: 0px;
}
#crop-cycle-option md-input-container md-datepicker {
  margin: 0;
}
#crop-cycle-option md-input-container md-datepicker .md-datepicker-input {
  width: 220px;
}
#crop-cycle-option md-input-container md-select {
  width: 220px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/crops/cycles/components/crop-cycle-option.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAEI;EACI,cAAA;EACA,UAAA;AAAR;AACQ;EACI,eAAA;AACZ;AACQ;EACI,SAAA;AACZ;AAAY;EACI,YAAA;AAEhB;AACQ;EACI,YAAA;AACZ","sourcesContent":["#crop-cycle-option {\r\n    md-checkbox {\r\n        margin-bottom: 0;\r\n    }\r\n    md-input-container {\r\n        margin: 16px 0;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            min-height: 0px;\r\n        }\r\n        md-datepicker {\r\n            margin: 0;\r\n            .md-datepicker-input {\r\n                width: 220px;\r\n            }\r\n        }\r\n        md-select{\r\n            width: 220px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
