import * as angular from 'angular';
import * as moment from 'moment';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { SWANConstants } from '@common/SWANConstants';
import { CropService } from '@services/crop.service';
import { DayNumberService } from '@services/day-number.service';
import { FetchDataService } from '@services/fetch-data.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { BaseController } from 'src/app/base.controller';

export class NewCropsDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _cropService: CropService;
  private _dayNumberService: DayNumberService;
  private _fetchDataService: FetchDataService;
  private _languageService: LanguageService;

  public _localCropList: Swan.Crop[] = [];
  public _sharedCropList: Swan.Crop[] = [];
  public _siteList: Asset[] = [];
  public _selectedNewcropType: string;
  public _clickedButton: string;
  public _newCropType: any = SWANConstants.AddNewCropType;
  public _addCropTypeStep: any = SWANConstants.AddNewCropStep;
  public _cropName: string;

  public selectedCrop: Swan.Crop;
  public searchCropText: string;

  public minDate = SWANConstants.MinDate;
  public selectedSite: Asset;
  public rolloutWorkingDepth: boolean = false;
  public fromDate: Date;
  public toDate: Date;
  public maxDate: Date;
  public cropDto: Swan.Crop;
  public cropDetails: angular.IFormController;
  public durationInvalid: boolean;
  public readonly = false;
  public nameExists = false;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    CropService: CropService,
    DayNumberService: DayNumberService,
    FetchDataService: FetchDataService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    localCropList: Swan.Crop[],
    sharedCropList: Swan.Crop[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._cropService = CropService;
    this._dayNumberService = DayNumberService;
    this._fetchDataService = FetchDataService;
    this._languageService = LanguageService;

    this._localCropList = localCropList;
    this._sharedCropList = sharedCropList;
    this._sharedCropList = ArrayUtils.sortByString(this._sharedCropList, (x) => x.Name);
    this._localCropList = ArrayUtils.sortByString(this._localCropList, (x) => x.Name);
    this._selectedNewcropType = null;
    this._clickedButton = null;
    this.fromDate = new Date();
    this.fromDate.setDate(1);
    this.fromDate.setMonth(0);
    this.toDate = moment(this.fromDate).toDate().addDays(364);
    this.maxDate = moment(this.toDate).toDate();
  }

  $onInit() {
    this.loadSites();
  }

  public fromSelected() {
    if (this.toDate < this.fromDate) {
      this.toDate = moment(this.fromDate).toDate();
    }
    this.maxDate = moment(this.fromDate).toDate().addDays(364);

    if (this.toDate > this.maxDate) {
      this.toDate = this.maxDate;
    }
  }

  public checkDuration() {
    if (this.cropDto == null) {
      return;
    }

    const duration = this.cropDto.CropCoefficients.reduce((a, b) => {
      return b.GrowthPhaseDuration == null ? a : a + b.GrowthPhaseDuration;
    }, 0);
    if (duration > 365) {
      this.durationInvalid = true;
      //Set all durations as invalid
      for (var i = 0; i < this.cropDto.CropCoefficients.length; i++) {
        var ctl = this.cropDetails['Duration_' + i.toString()];
        ctl.$setValidity('invalid', false);
        ctl.$setTouched();
      }
      return;
    } else {
      this.durationInvalid = false;
      //Make sure durations are set as valid
      if (this.cropDetails.$invalid) {
        for (var i = 0; i < this.cropDto.CropCoefficients.length; i++) {
          var ctl = this.cropDetails['Duration_' + i.toString()];
          ctl.$setValidity('invalid', true);
        }
      }
    }
  }

  public searchCropChanged(keyword: string): void {
    this.searchCropText = keyword;
  }

  private loadSites(): void {
    this._fetchDataService.fetchAccountSites(this.accountId).then(
      (data) => {
        this._siteList = (data as Asset[]).filter((s) => s.Status != 'Archived');
      },
      () => {
        this._languageService.whoops();
        return;
      },
    );
  }

  public searchCrops(keyword: string): Swan.Crop[] {
    let dataSource: Swan.Crop[];
    if (
      this._selectedNewcropType != null &&
      angular.isDefined(this._selectedNewcropType) &&
      this._selectedNewcropType == SWANConstants.AddNewCropType.Shared &&
      this._selectedNewcropType != SWANConstants.AddNewCropType.New
    ) {
      dataSource = this._sharedCropList.filter((x) => x.IsActive);
    } else {
      dataSource = this._localCropList.filter((x) => x.IsActive);
    }

    if (keyword?.length) {
      keyword = keyword.toUpperCase();
      dataSource = dataSource.filter((s) => s.DetailDescription.toUpperCase().indexOf(keyword, 0) > -1);
    }
    return dataSource;
  }

  public selectedCropChanged(userSelectedCrop: Swan.Crop): void {
    this.selectedCrop = userSelectedCrop;
  }

  public GetAddNewCropType(type: string): string {
    const result = type;
    return result;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public showLayout(cropType: string): boolean {
    let result;
    switch (cropType) {
      case SWANConstants.AddNewCropType.New:
      case SWANConstants.AddNewCropType.Local:
      case SWANConstants.AddNewCropType.Shared:
      case SWANConstants.AddNewCropType.Site: {
        result =
          this._clickedButton != null &&
          this._selectedNewcropType != null &&
          angular.isDefined(this._selectedNewcropType) &&
          this._selectedNewcropType != null &&
          this._selectedNewcropType == cropType;
        break;
      }
      default: {
        result = this._clickedButton == null && this._selectedNewcropType == null;
        break;
      }
    }
    return result;
  }

  public onClick(step: string): void {
    switch (step) {
      case SWANConstants.AddNewCropStep.Submit: {
        this._clickedButton = step;
        break;
      }
      case SWANConstants.AddNewCropStep.Back: {
        this._clickedButton = null;
        this.selectedCrop = null;
        this.searchCropText = '';
        this.cropDto = null;
        break;
      }
      case SWANConstants.AddNewCropStep.Add: {
        const newDTO = this.getNewCropDTO();
        this.readonly = true;
        this._cropService.addNewCrop(newDTO).then(
          (result) => {
            const cropId = result;
            this._languageService.success('CROPS.NEW_CROP_CREATED');
            this._mdDialog.hide(cropId);
          },
          (error) => {
            this.readonly = false;
            if (error.data) {
              this._languageService.warning('COMMON.ERROR_MSG', 'COMMON.FAILED', { msg: error.data.Message });
            }
          },
        );
        break;
      }
      case SWANConstants.AddNewCropStep.Continue: {
        this._clickedButton = step;
        const dto = {
          fromDayNumber: this._dayNumberService.convertCalendarDateToLocaleDayNumber(this.fromDate),
          endDayNumber: this._dayNumberService.convertCalendarDateToLocaleDayNumber(this.toDate),
          siteId: this.selectedSite.AssetId,
          cloneWorkingDepth: this.rolloutWorkingDepth,
        } as fuse.generateSiteCropDTO;

        this._cropService
          .generateCropFromSiteSettings(dto)
          .then((result) => {
            if (result == null) {
              //No crop was generated, due to no settings being found.
              //Show toater message and return this form to the previous step
              this._languageService.warning('CROPS.NO_SETTINGS_IN_RANGE', 'CROPS.UNABLE_TO_CREATE');
              this.onClick(SWANConstants.AddNewCropStep.Submit);
              return;
            }
            this.cropDto = result;
          })
          .catch((error) => {
            this._languageService.warning('COMMON.ERROR_MSG', 'COMMON.FAILED', { msg: error.data.Message });
            this._mdDialog.hide();
          });
        break;
      }
    }
  }

  public showButton(step: string): boolean {
    let result = false;
    switch (step) {
      case SWANConstants.AddNewCropStep.Submit: {
        result = this._clickedButton == null && this._selectedNewcropType != null;
        break;
      }
      case SWANConstants.AddNewCropStep.Back: {
        result =
          this._clickedButton != null &&
          (this._clickedButton == SWANConstants.AddNewCropStep.Submit ||
            this._clickedButton == SWANConstants.AddNewCropStep.Continue);
        break;
      }
      case SWANConstants.AddNewCropStep.Add: {
        const isValid =
          (this._clickedButton == SWANConstants.AddNewCropStep.Submit &&
            ((this._cropName != null &&
              angular.isDefined(this._cropName) &&
              this._cropName != '' &&
              this._cropName.length >= 2 &&
              this._selectedNewcropType == SWANConstants.AddNewCropType.New) ||
              (this.selectedCrop != null &&
                (this._selectedNewcropType == SWANConstants.AddNewCropType.Shared ||
                  this._selectedNewcropType == SWANConstants.AddNewCropType.Local)))) ||
          (this._selectedNewcropType == SWANConstants.AddNewCropType.Site &&
            this._clickedButton == SWANConstants.AddNewCropStep.Continue &&
            this.cropDetails != null &&
            !this.cropDetails.$invalid);
        result = this._clickedButton != null && isValid;
        break;
      }
      case SWANConstants.AddNewCropStep.Continue: {
        result =
          this._clickedButton == SWANConstants.AddNewCropStep.Submit &&
          this._selectedNewcropType == SWANConstants.AddNewCropType.Site &&
          this.selectedSite != null &&
          this._cropName != null &&
          angular.isDefined(this._cropName) &&
          this._cropName != '' &&
          this._cropName.length >= 2 &&
          this.fromDate != null &&
          this.toDate != null;
        break;
      }
      default: {
        result = false;
      }
    }

    return result;
  }

  public isShowMainMenu(): boolean {
    const result = this._clickedButton == null;

    return result;
  }

  private getNewCropDTO(): Swan.Crop {
    const result = this.cropDto == null ? ({} as Swan.Crop) : this.cropDto;
    result.AuthAccountId = this.accountId;
    result.NewCropType = this._selectedNewcropType;
    result.Name = this._cropName;
    switch (this._selectedNewcropType) {
      case SWANConstants.AddNewCropType.Local:
      case SWANConstants.AddNewCropType.Shared: {
        result.NewCropCloneExistingCropId = this.selectedCrop.Id;
        break;
      }
    }
    return result;
  }

  public checkCropName() {
    if (this._cropName) {
      if (this._localCropList.some((a) => a.Name.toLowerCase() == this._cropName.toLowerCase())) {
        this.nameExists = true;
      } else {
        this.nameExists = false;
      }
    } else {
      this.nameExists = false;
    }
  }
}

angular.module('app.crops').controller('NewCropsDialogController', NewCropsDialogController);
