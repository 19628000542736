import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class EditMetricDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  public nameExists: boolean;
  public entityList: EntityList<any>;
  public title;

  public metricList: fuse.metricDto[];
  public unitList: fuse.uomPreferenceDto[];
  public cropMetrics: fuse.cropMetricsDto;

  public selectedIds: number[];
  public unitScaleId: number;
  public editMode: boolean;
  public defaultMetrics: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    metricList: fuse.metricDto[],
    entityList: EntityList<any>,
    defaultMetrics: string,
    unitList: fuse.uomPreferenceDto[],
    cropMetrics: fuse.cropMetricsDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.entityList = entityList;

    this.defaultMetrics = defaultMetrics;
    this.metricList = metricList;
    this.unitList = unitList;
    this.cropMetrics = angular.copy(cropMetrics); // copied so can revert changes easily
    if (!this.cropMetrics) {
      // New metric
      this.editMode = false;
      this.cropMetrics = {
        name: '',
        status: 'Active',
        metrics: [],
        grades: [{ name: '' }],
      } as fuse.cropMetricsDto;
      this.title = 'CROPS.ADD_METRICS';
      this.unitScaleId = this.unitList[0]?.unitScaleId;
    } else {
      // Editing existing metric
      this.editMode = true;
      this.title = 'CROPS.EDIT_METRICS';
      this.unitScaleId = this.cropMetrics.unit.unitScaleId;
    }

    const defs = this.metricList.filter((m) => m.default).map((m) => m.id);
    this.selectedIds = this.cropMetrics.metrics.map((m) => m.id).concat(defs);
  }

  public addMetric() {
    if (!this.gradeNamesUnique()) {
      this._languageService.warning('CROPS.UNIQUE_GRADE_ERROR', 'CROPS.SAVE_ERROR');
      return;
    }

    this.cropMetrics.metrics = this.metricList.filter((metric) => !metric.default && !!this.selectedIds.find((id) => id == metric.id));
    this.cropMetrics.unit = this.unitList.find((ul) => ul.unitScaleId == this.unitScaleId);
    this._mdDialog.hide(this.cropMetrics);
  }

  // Returns true if all Grades have different names
  public gradeNamesUnique() {
    const hash = {};
    this.cropMetrics.grades.forEach((grade) => {
      hash[grade.name.toLowerCase()] = 1;
    });

    return Object.keys(hash).length == this.cropMetrics.grades.length;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public addNewGrade() {
    const newGrade = { name: '' } as any;
    this.cropMetrics.grades.push(newGrade);
  }

  public deleteGrade(grade) {
    this.cropMetrics.grades = this.cropMetrics.grades.filter((g) => g != grade);
  }

  public canDelete(grade) {
    if (this.cropMetrics.grades.length <= 1) return false;

    const last = this.cropMetrics.grades[this.cropMetrics.grades.length - 1];
    if (grade == last && !last.hasPrices) return true;

    return false;
  }
}

angular.module('app.crops').controller('EditMetricDialogController', EditMetricDialogController);
