import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { EntityList } from '@common/EntityList';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { UnitOptions } from '@services/unit-of-measure.service';
import { PermissionService } from '@services/permission.service';
import { FilterService } from '@services/filter.service';
import { CropService } from '@services/crop.service';
import { BaseController } from 'src/app/base.controller';
import { DownloadCropDialogController } from './Download-Crop-Dialog.Controller';
import { DuplicateNameConfirmationController } from './DuplicateNameConfirmationController';
import { NewCropsDialogController } from './New-Crops-Dialog.Controller';

export class CropCoefficientsController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _cropService: CropService;
  private _languageService: LanguageService;

  public filterService: FilterService;

  private localCropList: Swan.Crop[] = [];
  private sharedCropList: Swan.Crop[] = [];
  private entityList: EntityList<any>;

  public showShared = false;
  public filteredCrops = [] as Swan.Crop[];
  public toggleUpload: boolean = false;
  public fileCSV: any;
  public _depthUnitOptions: fuse.unitScaleDto[] = [];
  public _selectedDepthUnit: fuse.unitScaleDto;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    CropService: CropService,
    FilterService: FilterService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.AdminCropLibraryFull);

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._cropService = CropService;
    this._languageService = LanguageService;

    this.filterService = FilterService;

    this._selectedDepthUnit = undefined;
  }

  $onInit() {
    this.getUnitOptions('Soil Depth');
    this.refreshTable();
  }

  private getUnitOptions(baseClassName: string) {
    this._http.get(CommonHelper.getApiUrl('profile/getUnitScales/' + baseClassName)).then(
      (res) => {
        if (!res.data) {
          this._languageService.error('COMMON.ERROR_LOADING_UNIT_OPTIONS');
        } else {
          const unitOptions = new UnitOptions(res.data as fuse.unitScaleDto[]);
          this._depthUnitOptions = unitOptions.getAllowedUnitScales('Soil Depth', unitSizes.normal);
        }
      },
      () => {
        this._languageService.whoops();
      },
    );
  }

  private refreshTable() {
    const promises = [] as angular.IPromise<void>[];
    promises.push(this.getLocalCrop());
    promises.push(this.getSharedCrop());
    this._q.all(promises).then(() => {
      this.localCropList.forEach((crop) => (crop['SharedFilter'] = 'LocalAndShared')); // Show when filter = 'Local' or 'Shared'
      this.sharedCropList.forEach((crop) => (crop['SharedFilter'] = 'Shared')); // Show only when filter = 'Shared'
      const list = this.localCropList.concat(this.sharedCropList) as any;
      list.forEach((en) => {
        en['Owner'] = en.AuthAccount.CompanyName;
        en.status = en.IsActive ? 'Active' : 'Archived';
      });
      this.entityList = new EntityList(list, this.filterService);
      this.initSharedFilter();
      this.entityList.setOrderByColumn('Name');
    });
  }

  private getLocalCrop(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._cropService
      .getLocalCrop(this.accountId)
      .then((result) => {
        this.localCropList = result;
        defer.resolve();
      })
      .catch((error) => {
        const msg = error.data.ErrorMessages[0];
        this._languageService.warning(msg);
        defer.reject();
      });
    return defer.promise;
  }

  private getSharedCrop(): angular.IPromise<void> {
    //            return this._cropService.entityListService.getEntityList("api/Crops/GetSharedCrop/", result => { this.sharedCropList = result }, true, { accountID: this.accountId });

    const defer = this._q.defer<void>();
    this._cropService
      .getSharedCrop(this.accountId)
      .then((result) => {
        this.sharedCropList = result;
        defer.resolve();
      })
      .catch((error) => {
        const msg = error.data.ErrorMessages[0];
        this._languageService.warning(msg);
        defer.reject();
      });
    return defer.promise;
  }

  public initSharedFilter() {
    if (!this.filterService.keepFilter || this.entityList.filters['SharedFilter'] == null) {
      this.entityList.filters['SharedFilter'] = 'Local';
      this.entityList.saveFilters();
    }
  }

  public clearFilters() {
    this.entityList.clearFiltersExcept('SharedFilter');
  }

  public openCsvUpload() {
    this.toggleUpload = true;
  }

  public openDownloadCropsDialog() {
    this._mdDialog
      .show({
        controller: DownloadCropDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/crops/Download-Crop-Dialog.html',
        parent: angular.element(document.body),
        locals: {
          localCropList: this.localCropList,
        },
      })
      .then(() => {});
  }

  public openAddCropDialog() {
    this._mdDialog
      .show({
        controller: NewCropsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/crops/New-Crops-Dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          localCropList: this.localCropList,
          sharedCropList: this.sharedCropList,
        },
      })
      .then((result) => {
        if (result > 0) {
          this.gotoCropDetail(result);
        }
      });
  }

  public downlodCsvTemplate() {
    this._cropService
      .downloadCSVTemplate()
      .then((result) => {
        if (result) {
          const file = new Blob([result] as BlobPart[], { type: 'application/csv' } as BlobPropertyBag);
          saveAs(file, 'Crop_CSVTemplate.csv');
        }
      })
      .catch((error) => {
        const msg = error.data.ErrorMessages[0];
        this._languageService.error(msg);
      });
  }

  public selectCsvFile() {
    const fInput: any = document.getElementById('cropFileCSV');

    this.fileCSV = fInput.files[0];
  }

  public uploadCsvTemplate() {
    this.selectCsvFile();
    if (!angular.isDefined(this.fileCSV)) {
      this._languageService.warning('CROPS.CSV_NOT_SELECTED');
      return;
    }

    if (!this._selectedDepthUnit) {
      this._languageService.warning('CROPS.DEPTH_NOT_SELECTED');
      return;
    }

    this._cropService
      .uploadCSV(this.accountId, this.fileCSV, true, this._selectedDepthUnit)
      .then((result) => {
        // if some of crop name already exists then prompt the user with warning
        if (result) {
          this._mdDialog
            .show({
              controller: DuplicateNameConfirmationController,
              controllerAs: 'vm',
              templateUrl: 'src/app/pages/crops/DuplicateNameConfirmation.html',
              parent: angular.element(document.body),
              locals: {
                fileCSV: this.fileCSV,
                selectedDepthUnit: this._selectedDepthUnit,
              },
            })
            .then((result) => {
              if (result && result.action == 'Proceed') {
                this.refreshTable();
              }
            });
        } else {
          this._languageService.success('CROPS.CSV_UPLOAD_SUCCESSFUL');
          this.refreshTable();
        }
      })
      .catch((error) => {
        this._languageService.warning(error.data, 'CROPS.CSV_UPLOAD_FAILED');
      });
  }

  public gotoCropDetail(id: number): void {
    this._state.go('app.crops.coeff.detail', { cropId: id });
  }
}

angular.module('app.crops').controller('CropCoefficientsController', CropCoefficientsController);
