import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { UnitOptions } from '@services/unit-of-measure.service';
import { PermissionService } from '@services/permission.service';
import { CropService } from '@services/crop.service';
import { BaseController } from 'src/app/base.controller';

export class DownloadCropDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _cropService: CropService;
  private _languageService: LanguageService;

  public _localCropList: Swan.Crop[] = [];
  public _depthUnitOptions: fuse.unitScaleDto[] = [];
  public _selectedDepthUnit: fuse.unitScaleDto;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    CropService: CropService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    localCropList: Swan.Crop[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._cropService = CropService;
    this._languageService = LanguageService;

    this._localCropList = ArrayUtils.sortByString(localCropList.filter((x) => x.IsActive), (x) => x.Name);

    this.getUnitOptions('Soil Depth');
    this._selectedDepthUnit = undefined;
  }

  private getUnitOptions(baseClassName: string) {
    this._http.get(CommonHelper.getApiUrl('profile/getUnitScales/' + baseClassName)).then(
      (res) => {
        if (!res.data) {
          this._languageService.error('COMMON.WHOOPS', 'COMMON.ERROR_LOADING_UNIT_OPTIONS');
        } else {
          const unitOptions = new UnitOptions(res.data as fuse.unitScaleDto[]);
          this._depthUnitOptions = unitOptions.getAllowedUnitScales('Soil Depth', unitSizes.normal);
        }
      },
      () => {
        this._languageService.whoops();
      },
    );
  }

  public isShowExport(): boolean {
    return this._localCropList.some((a) => a.isSelected == true) && this._selectedDepthUnit != undefined;
  }

  public DownloadCrops() {
    const selectedCrops = this._localCropList.filter((x) => x.isSelected);
    const cropIDs = selectedCrops.map((a) => a.Id);

    this._cropService
      .downloadCrops(cropIDs, this._selectedDepthUnit)
      .then((result) => {
        if (result) {
          const file = new Blob([result] as BlobPart[], { type: 'application/csv' } as BlobPropertyBag);
          saveAs(file, 'CropList.csv');
        }
        this._mdDialog.hide();
      })
      .catch((error) => {
        this._languageService.warning('COMMON.ERROR_MSG', 'COMMON.FAILED', { msg: error.data.ErrorMessages[0] });
      });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.crops').controller('DownloadCropDialogController', DownloadCropDialogController);
