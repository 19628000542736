import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { ApiService } from '@services/api.service';
import { EntityListService } from '@services/entity-list.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { CropService } from '@services/crop.service';
import { EditMetricDialogController } from './editmetric-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export class CropMetricsController extends BaseController {
  public title = 'CROPS.CROP_METRICS';
  public entityList: EntityList<fuse.cropMetricsDto>;

  private _mdDialog: angular.material.IDialogService;
  private _apiService: ApiService;
  private _cropService: CropService;
  private _entityListService: EntityListService;
  private _languageService: LanguageService;

  private _assetType = 'Crop Metrics';
  private _metricList: fuse.metricDto[];
  private _unitList: fuse.unitScaleDto[];
  private _defaultMetrics: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    ApiService: ApiService,
    CropService: CropService,
    EntityListService: EntityListService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._apiService = ApiService;
    this._cropService = CropService;
    this._entityListService = EntityListService;
    this._languageService = LanguageService;
  }

  $onInit() {
    this._entityListService.getEntityList(
      'crops/getMetrics',
      (result) => {
        this._metricList = result;
        this._defaultMetrics = this._cropService.getMetricsString(this._metricList.filter((m) => m.default));
      },
      false,
    );
    this._entityListService.getEntityList(
      'crops/getUnits',
      (result) => {
        this._unitList = result;
      },
      false,
    );
    this.loadCropMetricsList();
  }

  public loadCropMetricsList() {
    this._entityListService.getEntityList(
      'crops/getCropMetrics',
      (result: EntityList<fuse.cropMetricsDto>) => {
        result.entities = result.entities = result.entities.map((x) => ({
          ...x,
          displayMetrics: this._cropService.getMetricsString(x.metrics),
        }));

        this.entityList = result;
      },
      true,
      null,
      this.entityList,
    );
  }

  public editMetric(cm: fuse.cropMetricsDto) {
    if (!this.apf.hasCropMetricsFull) return;
    this.metricsDialog(cm).then((res) => {
      if (res) {
        this.saveChanges(res as fuse.cropMetricsDto);
      }
    });
  }

  public addNewMetric() {
    if (!this.apf.hasCropMetricsFull) return;

    this.metricsDialog(null).then((res) => {
      if (res) {
        this.createNew(res as fuse.cropMetricsDto);
      }
    });
  }

  public metricsDialog(cropMetrics) {
    const locals = {
      metricList: this._metricList,
      defaultMetrics: this._defaultMetrics,
      unitList: this._unitList,
      entityList: this.entityList,
      cropMetrics: cropMetrics,
    };
    return this._mdDialog.show({
      controller: EditMetricDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/crops/editmetric-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: locals,
    });
  }

  public createNew(cropMetric: fuse.cropMetricsDto) {
    if (!this.apf.hasCropMetricsFull) return;

    this._apiService.postHandleDupes(
      'crops/createCropMetrics',
      cropMetric,
      (res) => {
        this._languageService.success('CROPS.CROP_METRIC_CREATED', 'COMMON.SUCCESS', { name: cropMetric.name });
        this.loadCropMetricsList();
      },
      this._assetType,
    );
  }

  public saveChanges(cropMetric: fuse.cropMetricsDto) {
    if (!this.apf.hasCropMetricsFull) return;

    this._apiService.postHandleDupes(
      'crops/updateCropMetrics',
      cropMetric,
      (res) => {
        this._languageService.success('CROPS.CROP_METRIC_SAVED', 'COMMON.SUCCESS', { name: cropMetric.name });
        this.loadCropMetricsList();
      },
      this._assetType,
    );
  }
}

angular.module('app.crops').controller('CropMetricsController', CropMetricsController);
