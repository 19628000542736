import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { ApiService } from '@services/api.service';
import { CropService } from '@services/crop.service';
import { EntityListService } from '@services/entity-list.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { EditTargetsDialogController } from './edittargets-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export class CropTargetsController extends BaseController {
  public entityList: EntityList<fuse.cropTargetsDto>;

  private _mdDialog: angular.material.IDialogService;
  private _apiService: ApiService;
  private _cropService: CropService;
  private _entityListService: EntityListService;
  private _languageService: LanguageService;

  private _assetType = 'Crop Targets';
  private _cropMetricsList: EntityList<fuse.cropMetricsDto>;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    ApiService: ApiService,
    CropService : CropService,
    EntityListService: EntityListService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._apiService = ApiService;
    this._cropService = CropService;
    this._entityListService = EntityListService;
    this._languageService = LanguageService;
  }

  $onInit() {
    this._entityListService.getStatusTaggedEntityList(
      'crops/getCropMetrics',
      (result) => {
        this._cropMetricsList = result;
      },
      { includeDefaults: true },
    );
    this.reloadEntityList();
  }

  public reloadEntityList() {
    this._entityListService.getEntityList(
      'crops/getTargets',
      (result: EntityList<fuse.cropTargetsDto>) => {
        result.entities = result.entities.map((x) => ({
          ...x,
          displayMetrics: this._cropService.getMetricsString(x.metricTargets),
        }));

        this.entityList = result;
      },
      true,
      null,
      this.entityList,
    );
  }

  public editTargets(cm: fuse.cropTargetsDto) {
    if (!this.apf.hasCropMetricsFull) {
      return;
    }

    this.targetsDialog(cm).then((res) => {
      if (res) {
        this.saveChanges(res as fuse.cropTargetsDto);
      }
    });
  }

  public addNewTargets() {
    if (!this.apf.hasCropMetricsFull) {
      return;
    }

    this.targetsDialog(null).then((res) => {
      if (res) {
        this.createNew(res as fuse.cropTargetsDto);
      }
    });
  }

  public targetsDialog(cropTargets) {
    const locals = {
      cropMetricsList: this._cropMetricsList,
      entityList: this.entityList,
      cropTargets: cropTargets,
    };

    return this._mdDialog.show({
      controller: EditTargetsDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/crops/edittargets-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: locals,
    });
  }

  public createNew(cropTargets: fuse.cropTargetsDto) {
    if (!this.apf.hasCropMetricsFull) {
      return;
    }

    this._apiService.postGeneric(
      'crops/createCropTargets',
      cropTargets,
      (res) => {
        this._languageService.success('CROPS.CROP_TARGETS_CREATED', 'COMMON.SUCCESS', { name: cropTargets.name });
        this.reloadEntityList();
      },
      'COMMON.WHOOPS',
    );
  }

  public saveChanges(cropTargets: fuse.cropTargetsDto) {
    if (!this.apf.hasCropMetricsFull) {
      return;
    }

    this._apiService.postGeneric(
      'crops/updateCropTargets',
      cropTargets,
      (res) => {
        this._languageService.success('CROPS.CROP_TARGETS_SAVED', 'COMMON.SUCCESS', { name: cropTargets.name });
        this.reloadEntityList();
      },
      'COMMON.WHOOPS',
    );
  }
}

angular.module('app.crops').controller('CropTargetsController', CropTargetsController);
