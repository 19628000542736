import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { CropService } from '@services/crop.service';
import { BaseController } from 'src/app/base.controller';

export class DuplicateNameConfirmationController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _cropService: CropService;
  private _languageService: LanguageService;

  private _fileCSV: File;
  private _selectedDepthUnit: fuse.unitScaleDto;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    CropService: CropService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    fileCSV: File,
    selectedDepthUnit,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._cropService = CropService;
    this._fileCSV = fileCSV;
    this._selectedDepthUnit = selectedDepthUnit;
  }

  public onClick() {
    this._cropService
      .uploadCSV(this.accountId, this._fileCSV, false, this._selectedDepthUnit)
      .then(() => {
        this._languageService.success('CROPS.CSV_UPLOAD_SUCCESSFUL');
        this._mdDialog.hide({ action: 'Proceed' });
      })
      .catch((error) => {
        this._languageService.warning('COMMON.ERROR_MSG', 'COMMON.FAILED', { msg: error.data });
        this._mdDialog.hide();
      });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.crops').controller('DuplicateNameConfirmationController', DuplicateNameConfirmationController);
