// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#crop-cycles-chart-container {
  overflow: auto;
  max-height: calc(100vh - 340px);
}
#crop-cycles-chart-container .contextMenu {
  position: absolute;
  z-index: 1001;
  background: white;
  border: 1px solid black;
  padding: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/crops/cycles/crop-cycles-chart/crop-cycles-chart.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,+BAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,YAAA;AACJ","sourcesContent":["#crop-cycles-chart-container {\r\n  overflow: auto;\r\n  max-height: calc(100vh - 340px);\r\n\r\n  .contextMenu {\r\n    position: absolute;\r\n    z-index: 1001;\r\n    background: white;\r\n    border: 1px solid black;\r\n    padding: 2px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
