import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

export interface targetOperator {
  name: string;
  code: string;
}
export class EditTargetsDialogController extends BaseController {
  public title: string;
  public error = '';

  public entityList: EntityList<any>;
  public cropTargets: fuse.cropTargetsDto;
  public cropMetricsList: fuse.cropMetricsDto[];
  public cropMetricsId: number;

  public selectedIds: number[];
  public unitId: number;
  public editMode: boolean;
  public operators: targetOperator[] = [
    { code: 'gt', name: 'GREATER_THAN_EQUAL' },
    { code: 'lt', name: 'LESS_THAN_EQUAL' },
    { code: 'bw', name: 'BETWEEN' },
    { code: 'na', name: 'NOT_APPLICABLE' },
  ];

  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    cropMetricsList: EntityList<fuse.cropMetricsDto>,
    entityList: EntityList<any>,
    cropTargets: fuse.cropTargetsDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityList = entityList;
    this.cropTargets = angular.copy(cropTargets); // copied so can revert changes easily
    this.cropMetricsList = cropMetricsList.entities;

    // Add mode
    if (cropTargets == null) {
      this.title = this._languageService.instant('CROPS.ADD_TARGETS');
      this.editMode = false;
      this.cropMetricsList = cropMetricsList.getActiveOnly();
      this.cropTargets = {
        name: '',
        status: 'Active',
      } as fuse.cropTargetsDto;
    } else {
      // Edit mode
      this.title = this._languageService.instant('CROPS.EDIT_TARGETS');
      this.getOperators();
      this.editMode = true;
      this.cropMetricsId = this.cropTargets.cropMetrics.id;
      this.setUnits();
    }
  }

  public setOperator(cropMetricTargets: fuse.cropMetricTargetsDto) {
    cropMetricTargets.op = this.operators[cropMetricTargets['opInd']].code;
  }

  public getOperators() {
    this.cropTargets.metricTargets.forEach((cmt) => (cmt['opInd'] = this.operators.findIndex((op) => op.code == cmt.op)));
  }

  public setUnits() {
    this.cropTargets.metricTargets.forEach((cmt) => {
      cmt['uom'] = this._unitOfMeasureService.getUnits(cmt.metric.unitBaseClass, cmt.metric.scaleId);
    });
  }

  public updateCropMetrics() {
    const cropMetrics = this.cropMetricsList.find((cm) => cm.id == this.cropMetricsId);

    this.cropTargets.cropMetrics = cropMetrics;
    this.cropTargets.metricTargets = cropMetrics.metrics.map((metric) => {
      return {
        metricId: metric.id,
        name: metric.name,
        metric: metric,
      } as fuse.cropMetricTargetsDto;
    });

    this.setUnits();
  }

  public validateMetrics() {
    this.error = '';

    let errorContent = '';

    this.cropTargets.metricTargets.forEach((target, idx) => {
      const fromElem = this.scope['editTargetsForm'][`from${idx}`];
      const toElem = this.scope['editTargetsForm'][`to${idx}`];

      fromElem?.$validate();
      toElem?.$validate();

      const errors = new Set(Object.keys(fromElem?.$error ?? {}).concat(Object.keys(toElem?.$error ?? {})));

      if (!errors.size) {
        return;
      }

      const metric = target.metric;
      const name = this._languageService.instant(`DB_VALUES.CROP_METRICS.${metric.name}`);
      const unit = target['uom'] as uomUnit;
      const lower = !unit ? metric.lowerLimit : unit.fromBaseText(metric.lowerLimit);
      const upper = !unit ? metric.upperLimit : unit.fromBaseText(metric.upperLimit);


      if (errors.has('required')) {
        errorContent += this._languageService.instant('COMMON.INPUT_REQUIRED', { name: name }) + '<br>';
      }

      const tag =
        metric.lowerLimit != null && metric.upperLimit != null
          ? 'RANGE'
          : metric.lowerLimit != null
            ? 'GREATER_OR_EQUAL'
            : 'LESS_OR_EQUAL';

      errorContent += this._languageService.instant(`COMMON.ERRORS.${tag}`, { name: name, min: lower, max: upper }) + '<br>';
    });

    this.error = errorContent;
  }

  public saveChanges() {
    this.cropTargets.metricTargets.forEach((mt) => {
      if (mt.op != 'bw') {
        mt.valueTo = null;
      }

      if (mt.op == 'na') {
        mt.value = null;
      }
    });

    this._mdDialog.hide(this.cropTargets);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.crops').controller('EditTargetsDialogController', EditTargetsDialogController);
