import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { EntityList } from '@common/EntityList';
import { SWANConstants } from '@common/SWANConstants';
import { UnitTypes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { FilterService } from '@services/filter.service';
import { LanguageService } from '@services/language.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';
import { CropCycleDialogController } from './dialogs/crop-cycle-dialog.controller';
import { CropCycleResultsDialogController } from './dialogs/crop-cycle-results-dialog.controller';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { CropService, TargetLegend } from '@services/crop.service';

export class CropCyclesController extends BaseController {
  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _cropService: CropService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  public minDate = SWANConstants.MinDate;
  public cropCycles: EntityList<fuse.siteCropCycle>;
  public primaryTab: number;
  public accountCropInfo: fuse.accountCropInfo;
  public selectedGroups: number[];
  public filterService: FilterService;
  public endFilter = 'startDate:<=';
  public startFilter = 'endDate:>=';
  public isSelectAll = false;
  public yieldUnits: uomUnit;
  public yieldAreaUnits: uomUnit;
  public areaUnits: uomUnit;
  public pctUnits: uomUnit;
  public foreignUnits: boolean;
  public dayNumber: number;
  public allMetrics: fuse.metricDto[];
  public selectedMetric: fuse.metricDto;
  public changeInc = 0;
  public legendData: TargetLegend[];
  public currencyCode: string;
  public dummyMetric = { name: 'NO_SELECTION' } as fuse.metricDto;
  public isLoaded: boolean = false;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    $scope: angular.IScope,
    CropService: CropService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._q = $q;
    this._cropService = CropService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.filterService = new FilterService();
    this.filterService.setKeepFilter(true);

    this.primaryTab = 0;
    this.currencyCode = this.account.currencyCode ?? '$';

    const startMonth = this.account.startMonth - 1;
    const currentDate = new Date();

    this.dayNumber = DayNumberService.convertBrowserDateToLocaleDayNumber(currentDate, this.account.timezoneId);

    if (startMonth <= currentDate.getMonth()) {
      this.filterService.filters[this.startFilter] = new Date(currentDate.getFullYear(), startMonth, 1);
    } else {
      this.filterService.filters[this.startFilter] = new Date(currentDate.getFullYear() - 1, startMonth, 1);
    }

    this.filterService.filters[this.endFilter] = this.filterService.filters[this.startFilter].clone().addYears(1).addDays(-1);

    this.yieldUnits = UnitOfMeasureService.getUnits(UnitTypes.Yield);
    this.yieldAreaUnits = UnitOfMeasureService.getUnits(UnitTypes.YieldArea);
    this.areaUnits = UnitOfMeasureService.getUnits(UnitTypes.Area);
    this.pctUnits = UnitOfMeasureService.getUnits(UnitTypes.Percent);

    const accountId = this.account.accountId;
    // True if yield unit is custom unit from another account
    if (this.yieldUnits?.authAccountId && this.yieldUnits?.authAccountId != accountId) {
      this.foreignUnits = true;
    }
  }

  $onInit() {
    this.getAccountCropInfo().then((res) => {
      if (res) {
        this.accountCropInfo = res as fuse.accountCropInfo;
        this.labelMetricsStatus();
        this.getCropCycles();
      }
    });
  }

  private labelStatus(obj: fuse.listEntityDto) {
    if (obj.status == 'Archived') {
      obj.name = this._languageService.instant('COMMON.ARCHIVED_PREFIX', { name: obj.name });
    }
  }

  // Archived crop metrics/targets/prices are shown in dropdown, but should appear last with (Archived) tag
  private labelMetricsStatus() {
    this.accountCropInfo.cropMetrics.forEach((metric) => {
      this.labelStatus(metric);
      metric.cropTargets.forEach((target) => this.labelStatus(target));
      metric.cropPrices.forEach((price) => this.labelStatus(price));
    });
  }

  private getAccountCropInfo(): angular.IPromise<fuse.accountCropInfo> {
    const defer = this._q.defer<fuse.accountCropInfo>();
    this._http.get(CommonHelper.getApiUrl('crops/accountCropInfo')).then((res) => {
      if (res) {
        defer.resolve(res.data as fuse.accountCropInfo);
      } else {
        defer.reject();
      }
    });

    return defer.promise;
  }

  private getCropCycles(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl('crops/cropCycles')).then((res) => {
      if (res) {
        this.cropCycles = new EntityList<fuse.siteCropCycle>(res.data as fuse.siteCropCycle[], this.filterService);

        const allMetricIds = {};

        this.cropCycles.entities.forEach((siteCropCycle) => {
          siteCropCycle.startDate = this._dayNumberService.convertDayNumberToDate(siteCropCycle.startDayNumber);
          siteCropCycle.endDate = this._dayNumberService.convertDayNumberToDate(siteCropCycle.endDayNumber);

          siteCropCycle.harvests.forEach((result) => {
            result.date = this._dayNumberService.convertDayNumberToLocaleDate(result.dayNumber);
          });

          siteCropCycle.cropMetrics.metrics.forEach((metric) => {
            if (!allMetricIds[metric.id]) {
              allMetricIds[metric.id] = metric;
            }
          });

          this._cropService.calcDerived(siteCropCycle);
        });

        this.allMetrics = [this.dummyMetric].concat(Object.keys(allMetricIds).map((k) => allMetricIds[k]));

        if (this.selectedMetric) {
          this.selectedMetric = this.allMetrics.find((m) => m.id == this.selectedMetric.id);
        }

        this.filterCropCycles();
        this.toggleSelect();
        this.isLoaded = true;

        defer.resolve();
      } else {
        defer.reject();

        this._languageService.whoops();
      }
    });

    return defer.promise;
  }

  public filterGroups() {
    if (this.selectedGroups?.length) {
      const groups = this.accountCropInfo.groups.filter((a) => this.selectedGroups.some((b) => b == a.groupId));
      const siteIds = [] as number[];

      groups.forEach((group) => {
        siteIds.push(...group.sites);
      });

      this.cropCycles.filters['siteId:list'] = siteIds;
    } else {
      this.cropCycles.filters['siteId:list'] = null;
    }

    this.filterCropCycles();
  }

  public filterCropCycles(): void {
    this.cropCycles.saveFilters();

    if (this.selectedMetric) {
      this.selectedMetric['uom'] = this._unitOfMeasureService.getUnits(this.selectedMetric.unitBaseClass, this.selectedMetric.scaleId);
      this.legendData = this._cropService.setMetricStatus(this.cropCycles, this.selectedMetric);
    }

    this.changeInc++;
  }

  public refresh(results = null, force = false): void {
    const newStart = results?.startDate;
    const newEnd = results?.endDate;

    if (newStart != null && newStart < this.filterService.filters[this.startFilter]) {
      this.filterService.filters[this.startFilter] = newStart;
    }

    if (newEnd > this.filterService[this.endFilter]) {
      this.filterService.filters[this.endFilter] = newEnd;
    }

    // If we've changed the max/min dates, we've updated a cycle, so refresh cycle data
    if (newStart || newEnd || force) {
      this.getCropCycles();
    }
  }

  public addCropCycles() {
    this._languageService
      .show({
        controller: CropCycleDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/crops/cycles/dialogs/crop-cycle-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          cyclesForEdit: [],
          accountInfo: this.accountCropInfo,
          allActiveCycles: this.cropCycles.entities.filter((a) => a.status == 'Active'),
          startDate: this.filterService.filters[this.startFilter],
          endDate: this.filterService.filters[this.endFilter],
        },
      })
      .then((res) => {
        if (res) {
          this.refresh(res);
        }
      });
  }

  public editCropResult(cropCycle: fuse.siteCropCycle) {
    this._languageService
      .show({
        controller: CropCycleResultsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/crops/cycles/dialogs/crop-cycle-results-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          cropCycle: cropCycle,
        },
      })
      .then((res) => {
        if (res) {
          this.getCropCycles();
        }
      });
  }

  public toggleSelectAll() {
    if (this.isSelectAll) {
      this.cropCycles.entities.forEach((a) => {
        a.isSelected = true;
      });
    } else {
      this.cropCycles.entities.forEach((a) => {
        a.isSelected = false;
      });
    }
  }

  public toggleSelect() {
    if (this.cropCycles.entities.some((a) => a.isSelected == false)) {
      this.isSelectAll = false;
      return;
    } else {
      this.isSelectAll = true;
    }
  }

  public editCropCycle(cropCycle: fuse.siteCropCycle) {
    let siteCropCycles = [cropCycle];

    if (cropCycle == null) {
      siteCropCycles = this.cropCycles.entities.filter((a) => a.isSelected);
    }

    const siteIds = siteCropCycles.map((a) => a.siteId);
    const hasDuplicates = ArrayUtils.hasDuplicates(siteIds);

    if (hasDuplicates) {
      const alert = this._languageService
        .alert()
        .parent(angular.element(document.body))
        .title('COMMON.INVALID')
        .htmlContent('CROPS.CYCLES.SITE_SELECT_ERROR')
        .ok('COMMON.CLOSE');

      this._languageService.show(alert);

      return;
    } else {
      let startDate = null as Date;
      let endDate = null as Date;

      if (siteCropCycles.length == 1) {
        startDate = siteCropCycles[0].startDate;
        endDate = siteCropCycles[0].endDate;
      }

      this._languageService
        .show({
          controller: CropCycleDialogController,
          controllerAs: 'vm',
          templateUrl: 'src/app/pages/crops/cycles/dialogs/crop-cycle-dialog.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            cyclesForEdit: siteCropCycles,
            accountInfo: this.accountCropInfo,
            allActiveCycles: this.cropCycles.entities.filter((a) => a.status == 'Active'),
            startDate: startDate,
            endDate: endDate,
          },
        })
        .then((res) => {
          if (res) {
            this.refresh(res);
          }
        });
    }
  }

  public deleteCropCycle(cropCycle: fuse.siteCropCycle) {
    let dto = [cropCycle?.cropCycleId];

    if (cropCycle == null) {
      dto = this.cropCycles.entities.filter((a) => a.isSelected).map((a) => a.cropCycleId);
    }

    const confirm = this._languageService
      .confirm()
      .title('CROPS.CYCLES.DELETE_CROP_CYCLE')
      .htmlContent('CROPS.CYCLES.DELETE_CROP_CONF')
      .ok('COMMON.YES')
      .cancel('COMMON.NO');

    this._languageService.show(confirm).then(() => {
      this._http.post(CommonHelper.getApiUrl('crops/deleteCropCycles'), dto).then(
        (result) => {
          this.refresh(null, true);
          this._languageService.info('CROPS.CYCLES.SELECT_DELETED');
        },
        (err) => {
          this._languageService.error('CROPS.CYCLES.SELECTED_NOT_DELETED');
        },
      );
    });
  }

  public unDeleteCropCycle(cropCycle: fuse.siteCropCycle) {
    if (this.isUndeleteValid(cropCycle) == false) {
      this._languageService.error('CROPS.CYCLES.CANNOT_UNDELETE');

      return;
    }

    const confirm = this._languageService
      .confirm()
      .title('CROPS.CYCLES.UNDELETE_CYCLE')
      .htmlContent('CROPS.CYCLES.CONFIRM_UNDELETE')
      .ok('COMMON.YES')
      .cancel('COMMON.NO');

    this._languageService.show(confirm).then(() => {
      const dto = [cropCycle.cropCycleId];

      this._http.post(CommonHelper.getApiUrl('crops/undeleteCropCycles'), dto).then(
        (result) => {
          this.refresh(null, true);
          this._languageService.success('CROPS.CYCLES.UNDELETE_SUCCESS');
        },
        (err) => {
          this._languageService.error('CROPS.CYCLES.UNDELETE_FAIL');
        },
      );
    });
  }

  private isUndeleteValid(cropCycle: fuse.siteCropCycle): boolean {
    return !this.cropCycles.entities.some((a) =>
      a.status == 'Active' &&
      a.siteId == cropCycle.siteId &&
      cropCycle.startDate <= a.endDate &&
      cropCycle.endDate >= a.startDate,
    );
  }
}

angular.module('app.crops').controller('CropCyclesController', CropCyclesController);
