import * as angular from 'angular';
import * as moment from 'moment';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes } from '@common/enums';
import { CropService } from '@services/crop.service';
import { DataEntityService } from '@services/data-entity.service';
import { FilterService } from '@services/filter.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { GroupSettingService } from '@services/account/group-setting.service';
import { BaseController } from 'src/app/base.controller';
import { RolloutCropDialogController } from './Rollout-Crop-Dialog.Controller';

interface ICropTypeStatus {
  value: boolean;
  text: string;
}
export class CoefficientDetailController extends BaseController {
  public shortMonthNames: string[] = DateUtils.Locale.getMonthNamesShort();

  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _cropService: CropService;
  private _dataEntityService: DataEntityService;
  private _filterService: FilterService;
  private _groupSettingService: GroupSettingService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private _selectedCrop: Swan.Crop;
  private _selectedCropId: number;

  public sliderWSC: any;
  public sliderDSC: any;
  public sliderLAT: any;
  public sliderHAT: any;
  private colorPoor: string = 'red';
  private colorAverage: string = '#2AE02A';
  private colorGood: string = '#0DB9F0';
  private dailyCropCoefficient: { dayNo: number; day: Date; kc: number; monthlyAvg: number }[];

  public readonly: boolean = false;
  public selectedStatus: string;
  public crpFormDetail: angular.IFormController;
  public growthPhases: angular.IFormController;
  public showWarning: boolean = false;
  public cropTypeStatuses: ICropTypeStatus[] = [];
  public cropCoeffUserAction: any = SWANConstants.CropCoeffUserAction;
  public waterBudgetEnabled = false;
  public formDirty = false;
  public searchCrop: string;
  public crops: Swan.Crop[];
  public _soilDepthNormalUnit: string;
  private hasCustomChanges: Function;
  private saveCustomChanges: Function;
  private cancelCustomChanges: Function;

  public isSaving: boolean = false;

  public coefficientChartRollup: AmCharts.AmSerialChart;
  public monthlyCoefficientChartRollup: AmCharts.AmSerialChart;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    CropService: CropService,
    DataEntityService: DataEntityService,
    FilterService: FilterService,
    GroupSettingService: GroupSettingService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.AdminCropLibraryFull);

    this._state = $state;
    this._mdDialog = $mdDialog;
    this._cropService = CropService;
    this._dataEntityService = DataEntityService;
    this._filterService = FilterService;
    this._groupSettingService = GroupSettingService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.readonly = this.isReadOnly;

    this.cropTypeStatuses.push({ text: SWANConstants.CropTypeStatus.Active, value: true } as ICropTypeStatus);
    this.cropTypeStatuses.push({ text: SWANConstants.CropTypeStatus.Archived, value: false } as ICropTypeStatus);

    this._soilDepthNormalUnit = this._unitOfMeasureService.getUnitLabel('Soil Depth', unitSizes.normal);
  }

  $onInit() {
    this._selectedCropId = this._state.params['cropId'];

    this.hasCustomChanges = (): boolean => {
      return this.SaveButtonEnable();
    };

    this.saveCustomChanges = () => {
      if (this.hasCustomChanges()) {
        this.saveChanges(false);
      }
    };

    this.cancelCustomChanges = () => {
      this.cancelChanges(false);
    };
    this._dataEntityService.installCustomChanges(this.hasCustomChanges, this.saveCustomChanges, this.cancelCustomChanges);
    this.scope.$on('$destroy', () => {
      // tear down custom edits
      this._dataEntityService.installCustomChanges(null, null, null);
    });
    this.fetchData();
  }

  public AddNew(): void {
    const phaseList = this._selectedCrop.CropCoefficients.map((r) => {
      return r.GrowthPhase;
    });

    let maxPhase = phaseList.length ? ArrayUtils.max(phaseList) : 0;
    maxPhase = phaseList.length ? maxPhase + 1 : maxPhase;

    this._selectedCrop.CropCoefficients.push({
      Id: 0,
      CropId: 0,
      GrowthPhase: maxPhase,
      WaterCoefficient: 0,
      SlopeOption: 'F',
      GrowthPhaseDuration: 1,
    } as Swan.CropCoefficient);
    this.crpFormDetail.$setDirty();
  }

  public reorderCropCoefList(userAction: string, selectedItem: Swan.CropCoefficient): void {
    switch (userAction) {
      case SWANConstants.CropCoeffUserAction.MoveDown: {
        var findIndex = this._selectedCrop.CropCoefficients.indexOf(selectedItem, 0);
        if (findIndex != -1) {
          this._selectedCrop.CropCoefficients.forEach((obj) => {
            if (obj == selectedItem) {
              obj.GrowthPhase = obj.GrowthPhase + 1;
            }
          });
          var nextRecord = this._selectedCrop.CropCoefficients[findIndex + 1];
          if (angular.isDefined(nextRecord)) {
            this._selectedCrop.CropCoefficients.forEach((obj) => {
              if (obj == nextRecord) {
                obj.GrowthPhase = obj.GrowthPhase - 1;
              }
            });
          }
        }

        break;
      }
      case SWANConstants.CropCoeffUserAction.MoveUp: {
        var findIndex = this._selectedCrop.CropCoefficients.indexOf(selectedItem, 0);
        if (findIndex != -1 && findIndex > 0) {
          this._selectedCrop.CropCoefficients.forEach((obj) => {
            if (obj == selectedItem) {
              obj.GrowthPhase = obj.GrowthPhase - 1;
            }
          });
          if (findIndex - 1 >= 0) {
            var nextRecord = this._selectedCrop.CropCoefficients[findIndex - 1];
            if (angular.isDefined(nextRecord)) {
              this._selectedCrop.CropCoefficients.forEach((obj) => {
                if (obj == nextRecord) {
                  obj.GrowthPhase = obj.GrowthPhase + 1;
                }
              });
            }
          }
        }
        break;
      }
      case SWANConstants.CropCoeffUserAction.Delete: {
        this.Remove(selectedItem);
        let index = 0;
        this._selectedCrop.CropCoefficients.forEach((obj) => {
          obj.GrowthPhase = index;
          index++;
        });
        break;
      }
    }
    this.setDirtyFormObject('TolWet'); //Set any from control dirty (just to enable the save button)
    // always sorted by Growth Phase (ASC)
    this._selectedCrop.CropCoefficients = ArrayUtils.sortByNumber(this._selectedCrop.CropCoefficients, (o) => o.GrowthPhase);

    this.CalculateDates();
  }

  public saveChanges(showSuccess: boolean = true): void {
    if (this.SaveButtonEnable() && this.FormIsValid(true)) {
      this.isSaving = true;
      const detail = angular.copy(this._selectedCrop);
      if (detail.BudgetStartDate != null) {
        detail.BudgetStartDate = new Date(
          Date.UTC(detail.BudgetStartDate.getFullYear(), detail.BudgetStartDate.getMonth(), detail.BudgetStartDate.getDate()),
        );
      }
      if (detail.BudgetEndDate != null) {
        detail.BudgetEndDate = new Date(
          Date.UTC(detail.BudgetEndDate.getFullYear(), detail.BudgetEndDate.getMonth(), detail.BudgetEndDate.getDate()),
        );
      }
      this._cropService.udpateCrop(detail).then(
        () => {
          this.crpFormDetail.$setPristine();
          this.isSaving = false;
          if (showSuccess) {
            this._languageService.success('COMMON.CHANGES_SAVED');
          }
        },
        (error) => {
          this.isSaving = false;
          this._languageService.error(error.data.Message);
        },
      );
    }
  }

  public cancelChanges(reload: boolean): void {
    //Set form as clean (disables the save button and also resets the form state)
    this.crpFormDetail.$setPristine();
    if (reload) {
      this.fetchData();
    }
  }

  public backToLanding(): void {
    this._filterService.setKeepFilter(true);
    this._state.go('app.crops.coeff');
  }

  public SaveButtonEnable(): boolean {
    const isEnable = this.crpFormDetail != null && this.crpFormDetail.$dirty && !this.isSaving;
    return isEnable;
  }

  public statusChange(): void {
    const userSelectedStatus = this.cropTypeStatuses.find((obj) => {
      if (String(obj.value) == this.selectedStatus) {
        return obj;
      }
    });

    this._selectedCrop.IsActive = (userSelectedStatus as ICropTypeStatus).value;
  }

  public TotalGrowthDuration(): number {
    if (this._selectedCrop == null || this._selectedCrop.CropCoefficients == null) {
      return 0;
    }

    //Check all durations are valid
    for (let i = 0; i < this._selectedCrop.CropCoefficients.length; i++) {
      if (this.growthPhases['Duration_' + i] != null && this.growthPhases['Duration_' + i].$invalid) {
        return 0;
      }
    }

    return this._selectedCrop.CropCoefficients.reduce((a, b) => {
      return a + b.GrowthPhaseDuration;
    }, 0);
  }

  public TotalGrowthDurationString(): string {
    const days = this.TotalGrowthDuration();
    const tag = days > 1 || days < 1 ? 'COMMON.N_DAYS' : 'COMMON.1_DAY';
    return this._languageService.instant(tag, { n: days });
  }

  private CalculateDateRange(coeff: Swan.CropCoefficient): void {
    const getDateRangeString = (coeff: Swan.CropCoefficient): string => {
      if (this.TotalGrowthDuration() <= 0) {
        return this._languageService.instant('COMMON.INVALID');
      }

      if (coeff.GrowthPhaseDuration == null || coeff.GrowthPhaseDuration <= 0 || coeff.PhaseDates == null) {
        return '';
      }

      if (coeff.GrowthPhaseDuration > 1) {
        //Date Range
        return `${DateUtils.Locale.asDateDayAndMonth(coeff.PhaseDates.from)} - ${DateUtils.Locale.asDateDayAndMonth(coeff.PhaseDates.to)}`;
      } else {
        //Single Day
        return DateUtils.Locale.asDateDayAndMonth(coeff.PhaseDates.from);
      }
    };

    if (this._selectedCrop.BudgetStartDate != null) {
      const startDate = moment(this._selectedCrop.BudgetStartDate).toDate();
      const findIndex = this._selectedCrop.CropCoefficients.indexOf(coeff, 0);

      if (findIndex != -1) {
        for (let i = 0; i < findIndex; i++) {
          if (
            isNaN(this._selectedCrop.CropCoefficients[i].GrowthPhaseDuration) ||
            Number(this._selectedCrop.CropCoefficients[i].GrowthPhaseDuration) <= 0
          ) {
            coeff.PhaseDates = null;
          }

          startDate.setUTCDate(startDate.getUTCDate() + Number(this._selectedCrop.CropCoefficients[i].GrowthPhaseDuration));
        }

        let duration = 0;

        if (isNaN(this._selectedCrop.CropCoefficients[findIndex].GrowthPhaseDuration)) {
          coeff.PhaseDates = null;
        }

        duration = Number(this._selectedCrop.CropCoefficients[findIndex].GrowthPhaseDuration);

        if (duration == null || duration <= 0) {
          //No duration set
          coeff.PhaseDates = null;
        } else if (duration > 1) {
          //Has a date range
          const endDate = new Date(startDate.toString());
          endDate.setUTCDate(endDate.getUTCDate() + (duration - 1));
          coeff.PhaseDates = { from: startDate, to: endDate } as fuse.PhaseDates;
        } else {
          //A Single Day
          coeff.PhaseDates = { from: startDate, to: startDate } as fuse.PhaseDates;
        }
      }
    } else {
      coeff.PhaseDates = null;
    }

    coeff['dateRangeString'] = getDateRangeString(coeff);
  }

  public AnyDurationNonZero(): boolean {
    if (this._selectedCrop == null || this._selectedCrop.CropCoefficients == null) {
      return false;
    }

    return this._selectedCrop.CropCoefficients.some((cc) => cc.GrowthPhaseDuration != null && cc.GrowthPhaseDuration != 0);
  }

  public DurationChanged() {
    this.CalculateDates();
    if (this._selectedCrop.CropCoefficients.every((cc) => cc.GrowthPhaseDuration == 0)) {
      this._selectedCrop.IsForWaterBudget = false;
      this.waterBudgetEnabled = false;
    } else {
      this.waterBudgetEnabled = true;
    }
  }

  public StartDateChange(): void {
    this.CalculateDates();
  }

  private CalculateEndDate(): void {
    if (this._selectedCrop.BudgetStartDate != null) {
      const days = this.TotalGrowthDuration();

      if (days != null) {
        this._selectedCrop.BudgetEndDate = moment(this._selectedCrop.BudgetStartDate).utc().toDate();
        this._selectedCrop.BudgetEndDate.addDays(days - 1);
      } else {
        this._selectedCrop.BudgetEndDate = null;
      }
    } else {
      this._selectedCrop.BudgetEndDate = null;
    }
  }

  private CalculatePhaseDates(): void {
    this._selectedCrop.CropCoefficients.forEach((c) => this.CalculateDateRange(c));
  }

  public CalculateMonthlyKc(): void {
    //We can also recalculate the chart data at his point
    this.dailyCropCoefficient = [];

    //Check that the crop values are valid
    if (this.CropIsValid()) {
      let curDate = new Date(0);
      if (this._selectedCrop.BudgetStartDate != null) {
        curDate = new Date(
          this._selectedCrop.BudgetStartDate.getFullYear(),
          this._selectedCrop.BudgetStartDate.getMonth(),
          this._selectedCrop.BudgetStartDate.getDate(),
        );
      }
      const startYear = curDate.getFullYear();
      const startMonth = curDate.getMonth();
      const monthKcTotal = new Array<number>(12).fill(0);
      let dayNo = 1;
      this._selectedCrop.CropCoefficients.forEach((c, i) => {
        const rampFactor =
          c.SlopeOption == 'R' && i + 1 < this._selectedCrop.CropCoefficients.length
            ? Number(this._selectedCrop.CropCoefficients[i + 1].WaterCoefficient) - c.WaterCoefficient
            : 0;
        for (let day = 0; day < c.GrowthPhaseDuration; day++) {
          const dayKc = Number(c.WaterCoefficient) + (day / c.GrowthPhaseDuration) * rampFactor;
          monthKcTotal[curDate.getMonth()] += dayKc;
          //Add this daily number to the daily array (for charting purposes)
          this.dailyCropCoefficient.push({ dayNo: dayNo, day: new Date(curDate.toString()), kc: dayKc, monthlyAvg: 0 });

          curDate.addDays(1);
          if (curDate.getFullYear() > startYear && curDate.getMonth() == startMonth) {
            //This will wrap the chart...
            curDate.setFullYear(startYear);
          }
          dayNo++;
        }
      });

      //ensure rest of date is filled for chart (to 365 days)
      for (dayNo; dayNo <= 365; dayNo++) {
        this.dailyCropCoefficient.push({ dayNo: dayNo, day: new Date(curDate.toString()), kc: 0, monthlyAvg: 0 });
        curDate.addDays(1);
        if (curDate.getFullYear() > startYear && curDate.getMonth() == startMonth) {
          //This will wrap the chart...
          curDate.setFullYear(startYear);
        }
      }

      //As we are using a date with no leap years (1970) we can safely use the correct number of days here
      this._selectedCrop.JanuaryDailyKc = monthKcTotal[0] / 31;
      this._selectedCrop.FebruaryDailyKc = monthKcTotal[1] / 28;
      this._selectedCrop.MarchDailyKc = monthKcTotal[2] / 31;
      this._selectedCrop.AprilDailyKc = monthKcTotal[3] / 30;
      this._selectedCrop.MayDailyKc = monthKcTotal[4] / 31;
      this._selectedCrop.JuneDailyKc = monthKcTotal[5] / 30;
      this._selectedCrop.JulyDailyKc = monthKcTotal[6] / 31;
      this._selectedCrop.AugustDailyKc = monthKcTotal[7] / 31;
      this._selectedCrop.SeptemberDailyKc = monthKcTotal[8] / 30;
      this._selectedCrop.OctoberDailyKc = monthKcTotal[9] / 31;
      this._selectedCrop.NovemberDailyKc = monthKcTotal[10] / 30;
      this._selectedCrop.DecemberDailyKc = monthKcTotal[11] / 31;

      //Set Monthly Average for chart on the 15th of each month
      for (let i = 0; i < 365; i++) {
        if (this.dailyCropCoefficient[i].day.getDate() != 15) {
          this.dailyCropCoefficient[i].monthlyAvg = null;
          continue;
        }

        this.dailyCropCoefficient[i].monthlyAvg = this.getMonthlyKc(this.dailyCropCoefficient[i].day.getMonth() + 1);
      }
      this.dailyCropCoefficient = ArrayUtils.sortByDate(this.dailyCropCoefficient, (c) => c.day);
    } else {
      this.ResetMonthlyKc();
    }

    //Refresh chart data
    if (this.monthlyCoefficientChartRollup != null) {
      this.monthlyCoefficientChartRollup.dataProvider = this.dailyCropCoefficient;
      this.monthlyCoefficientChartRollup.validateData();
    }
  }

  public getMonthlyKc(month: number): number {
    if (!this._selectedCrop) return 0;

    switch (month) {
      case 1:
        return this._selectedCrop.JanuaryDailyKc;
      case 2:
        return this._selectedCrop.FebruaryDailyKc;
      case 3:
        return this._selectedCrop.MarchDailyKc;
      case 4:
        return this._selectedCrop.AprilDailyKc;
      case 5:
        return this._selectedCrop.MayDailyKc;
      case 6:
        return this._selectedCrop.JuneDailyKc;
      case 7:
        return this._selectedCrop.JulyDailyKc;
      case 8:
        return this._selectedCrop.AugustDailyKc;
      case 9:
        return this._selectedCrop.SeptemberDailyKc;
      case 10:
        return this._selectedCrop.OctoberDailyKc;
      case 11:
        return this._selectedCrop.NovemberDailyKc;
      case 12:
        return this._selectedCrop.DecemberDailyKc;
      default:
        return null;
    }
  }

  private loadMonthlyCoefficientChart() {
    const chartDiv: string = 'crop-coefficient-monthly-chart';

    const graphs = [
      {
        type: 'line',
        id: 'cropcoefficient',
        bullet: 'round',
        bulletAlpha: 0,
        fillAlphas: 0,
        lineAlpha: 1,
        lineColor: '#27aae0',
        lineThickness: 3,
        title: this._languageService.instant('COMMON.CROP_COEFFICIENT'),
        valueField: 'kc',
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          const val = graphDataItem.dataContext as { day: Date; kc: number; dayNo: number; monthlyAvg: number };
          if (val) {
            const dateString = moment(val.day).format('MMM') + ' ' + val.day.getDate();
            return `${this._languageService.instant('COMMON.DAY')}: ${
              val.dayNo
            } <b>(${dateString})</b><br/><b>${this._languageService.instant('COMMON.KC')}: ${val.kc.toFixed(2)}</b>`;
          } else {
            return '';
          }
        },
      },
    ] as AmCharts.AmGraph[];

    if (this._selectedCrop.IsForWaterBudget) {
      graphs.push({
        type: 'column',
        id: 'monthlycoefficient',
        fillAlphas: 0.6,
        lineAlpha: 0.6,
        bullet: 'round',
        bulletAlpha: 0,
        lineColor: '#9C27B0',
        columnWidth: 15,
        title: this._languageService.instant('CROPS.MONTHLY_AVG_KC'),
        valueField: 'monthlyAvg',
        connect: true,
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          const val = graphDataItem.dataContext as { day: Date; kc: number; dayNo: number; monthlyAvg: number };
          if (val) {
            return `${this._languageService.instant('COMMON.MONTH')}: ${moment(val.day).format(
              'MMM',
            )}<br/><b>${this._languageService.instant('COMMON.KC')}: ${val.monthlyAvg.toFixed(2)}</b>`;
          } else {
            return '';
          }
        },
      } as AmCharts.AmGraph);
    }

    this.monthlyCoefficientChartRollup = (AmCharts as any).makeChart(chartDiv, {
      theme: 'light',
      type: 'serial',
      dataProvider: this.dailyCropCoefficient,
      categoryField: 'day',
      categoryAxis: {
        parseDates: true,
        autoGridCount: false,
        gridCount: 365,
        labelRotation: 90,
        markPeriodChange: false,
        equalSpacing: true,
        minPeriod: 'MM',
      },
      graphs: graphs,
      valueAxes: [
        {
          id: 'yAxis',
          axisAlpha: 0,
          minimum: 0,
        },
      ],
      legend: {
        useGraphSettings: true,
        rollOverGraphAlpha: 0.3,
        verticalGap: 5,
        markerSize: 10,
        marginTop: 0,
        marginBottom: 0,
        position: 'bottom',
        textClickEnabled: true,
        switchable: true,
      },
    });
  }

  public suitableForBudgetClick($event) {
    this.formDirty = this.crpFormDetail.$dirty;
  }

  public suitableForBudgetChanged() {
    if (!this.waterBudgetEnabled) {
      this._selectedCrop.IsForWaterBudget = false;
      const msg = 'CROPS.ERROR_DURATION_UNSET';
      this._languageService.warning(msg);
      if (!this.formDirty) {
        (this.crpFormDetail['IsForBudget'] as angular.INgModelController).$setPristine();
        this.crpFormDetail.$setPristine();
      }
    } else {
      this.CalculateMonthlyKc();
      this.loadMonthlyCoefficientChart();
    }
  }

  private ResetMonthlyKc(): void {
    this._selectedCrop.JanuaryDailyKc = 0;
    this._selectedCrop.FebruaryDailyKc = 0;
    this._selectedCrop.MarchDailyKc = 0;
    this._selectedCrop.AprilDailyKc = 0;
    this._selectedCrop.MayDailyKc = 0;
    this._selectedCrop.JuneDailyKc = 0;
    this._selectedCrop.JulyDailyKc = 0;
    this._selectedCrop.AugustDailyKc = 0;
    this._selectedCrop.SeptemberDailyKc = 0;
    this._selectedCrop.OctoberDailyKc = 0;
    this._selectedCrop.NovemberDailyKc = 0;
    this._selectedCrop.DecemberDailyKc = 0;
  }

  private SlopeOptionValidation(showError: boolean): boolean {
    if (!this._selectedCrop.CropCoefficients.length) {
      return true;
    }

    if (this._selectedCrop.CropCoefficients[this._selectedCrop.CropCoefficients.length - 1].SlopeOption != 'F') {
      (this.growthPhases['Slope_' + (this._selectedCrop.CropCoefficients.length - 1)] as angular.INgModelController).$setValidity(
        'invalid',
        false,
      );
      if (showError) {
        this._languageService.error('CROPS.ERROR_FINAL_SLOPE', 'COMMON.INVALID', { name: this._selectedCrop.Name });
      }
      return false;
    } else {
      (this.growthPhases['Slope_' + (this._selectedCrop.CropCoefficients.length - 1)] as angular.INgModelController).$setValidity(
        'invalid',
        true,
      );
    }
    return true;
  }

  private FormIsValid(showError: boolean): boolean {
    //Check form
    if (this._selectedCrop.IsForWaterBudget) {
      if (this._selectedCrop.BudgetStartDate == null) {
        if (showError) {
          this._languageService.error('CROPS.ERROR_DATE_REQUIRED', 'COMMON.INVALID');
        }
        return false;
      }
    }

    if (!this.SlopeOptionValidation(showError)) {
      return false;
    }

    for (let i = 0; i < this._selectedCrop.CropCoefficients.length; i++) {
      if (this.growthPhases['Duration_' + i] != null && this.growthPhases['Duration_' + i].$invalid) {
        if (showError) {
          this._languageService.error('CROPS.ERROR_INVALID_DURATION', 'COMMON.INVALID', { num: i + 1 });
        }
        return false;
      }
      if (this.growthPhases['Coeff_' + i] != null && this.growthPhases['Coeff_' + i].$invalid) {
        if (showError) {
          this._languageService.error('CROPS.ERROR_INVALID_COEFFICIENT', 'COMMON.INVALID', { num: i + 1 });
        }
        return false;
      }
      if (this.growthPhases['Desc_' + i] != null && this.growthPhases['Desc_' + i].$invalid) {
        if (showError) {
          this._languageService.error('CROPS.ERROR_INVALID_DESCRIPTION', 'COMMON.INVALID', { num: i + 1 });
        }
        return false;
      }
      if (this.growthPhases['Slope_' + i] != null && this.growthPhases['Slope_' + i].$invalid) {
        if (showError) {
          this._languageService.error('CROPS.ERROR_INVALID_PHASE_SLOPE', 'COMMON.INVALID', { num: i + 1 });
        }
        return false;
      }
      if (this.growthPhases['WorkingDepth_' + i] != null && this.growthPhases['WorkingDepth_' + i].$invalid) {
        if (showError) {
          this._languageService.error('CROPS.ERROR_INVALID_WORKING', 'COMMON.INVALID', { num: i + 1 });
        }
        return false;
      }
    }

    //Check durations
    if (
      this._selectedCrop.CropCoefficients.some((cc) => cc.GrowthPhaseDuration == 0) &&
      !this._selectedCrop.CropCoefficients.every((cc) => cc.GrowthPhaseDuration == 0)
    ) {
      if (showError) {
        this._languageService.error('CROPS.ERROR_DURATION_ZERO', 'COMMON.INVALID');
      }
      return false;
    }
    return true;
  }

  private CropIsValid(): boolean {
    //Check Durations
    const days = this.TotalGrowthDuration();
    if (days == null || days > 365 || days <= 0) return false;

    //Check Kc Values
    this._selectedCrop.CropCoefficients.forEach((c) => {
      if (c.WaterCoefficient == null || c.WaterCoefficient <= 0) return false;
    });

    //Check start date
    if (this._selectedCrop.IsForWaterBudget && this._selectedCrop.BudgetStartDate == null) {
      return false;
    }

    return true;
  }

  private CalculateDates(): void {
    this.CalculatePhaseDates();
    this.CalculateEndDate();
    this.CalculateMonthlyKc();
  }

  public showArrow(arrowButton: string, item: Swan.CropCoefficient): boolean {
    if (this.readonly) {
      return false;
    }

    switch (arrowButton) {
      case SWANConstants.CropCoeffUserAction.MoveUp: {
        const [firstItem] = this._selectedCrop.CropCoefficients;

        return firstItem != item;
      }

      case SWANConstants.CropCoeffUserAction.MoveDown: {
        const lastItem = this._selectedCrop.CropCoefficients.at(-1);

        return lastItem != item;
      }

      default: {
        return false;
      }
    }
  }

  private isSharedCropFromDifferentAccount(): boolean {
    // verify it is shared Crop from Different Account
    const result = this._selectedCrop.AuthAccountId != this._selectedCrop.userSelectedAccountID && this._selectedCrop.IsShared;

    return result;
  }

  private Remove(item: Swan.CropCoefficient): void {
    const index = this._selectedCrop.CropCoefficients.indexOf(item);
    if (index != -1) {
      this._selectedCrop.CropCoefficients.splice(index, 1);
    }
  }

  private fetchData() {
    this._cropService.getDetailCrop(this._selectedCropId).then((result) => {
      this._selectedCrop = result;

      const utcDate = moment.utc(this._selectedCrop.BudgetStartDate).toDate();

      if (this._selectedCrop.BudgetStartDate != null) {
        this._selectedCrop.BudgetStartDate = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
      }

      this._selectedCrop.userSelectedAccountID = this.accountId;

      //Do not override user permissions
      if (!this.readonly) {
        this.readonly = this.isSharedCropFromDifferentAccount();
      }

      this._selectedCrop.CropCoefficients = ArrayUtils.sortByNumber(this._selectedCrop.CropCoefficients, (o) => o.GrowthPhase);
      this._selectedCrop.CropCoefficients.forEach((c) => {
        //set default flat/ramped value
        if (c.SlopeOption == null) {
          c.SlopeOption = 'F';
        }
        //Initialise the phase dates object for use later
        if (c.PhaseDates == null) {
          c.PhaseDates = { from: new Date(), to: new Date() } as fuse.PhaseDates;
        }
      });

      this.DurationChanged(); //Also Forces a call to calculate dates
      this.loadMonthlyCoefficientChart();

      const objSelectedStatus =this.cropTypeStatuses.find((r) => {
        if (this._selectedCrop.IsActive && r.text == SWANConstants.CropTypeStatus.Active) {
          return r;
        } else if (
          (!this._selectedCrop.IsActive || this._selectedCrop.IsActive == null) &&
          r.text == SWANConstants.CropTypeStatus.Archived
        ) {
          return r;
        }
      });

      this.selectedStatus = String((objSelectedStatus as ICropTypeStatus).value);
      this.initSiteCropStressSliders();
    })
    .catch((error) => {
      const msg = error.data.ErrorMessages[0];
      this._languageService.error(msg);
      this.backToLanding();
    });

    this._cropService.getLocalCrop(this.accountId).then((data) => {
      this.crops = data;
    });
  }

  private setDirtyFormObject(objectName: string) {
    const htmlOBJ = this.crpFormDetail[objectName] as angular.INgModelController;
    if (angular.isDefined(htmlOBJ)) {
      htmlOBJ.$setDirty();
    }
  }

  public rolloutCropDialog(type: string) {
    this._mdDialog.show({
      controller: RolloutCropDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/crops/Rollout-Crop-Dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: {
        cropId: this._selectedCropId,
        type: type,
        cropDuration: this.TotalGrowthDuration(),
        cropStartDate: new Date(
          this._selectedCrop.BudgetStartDate.getFullYear(),
          this._selectedCrop.BudgetStartDate.getMonth(),
          this._selectedCrop.BudgetStartDate.getDate(),
        ),
        cropGrowthPhases: this._selectedCrop.CropCoefficients,
      },
    });
  }

  private getColorIndicator(value: number): string {
    if (value <= SWANConstants.CropStressFactor.Poor) return this.colorPoor;
    if (value > SWANConstants.CropStressFactor.Average) return this.colorAverage;

    return this.colorGood;
  }

  private initSiteCropStressSliders() {
    // 0=Poor, 1=Average, 2=Good
    this.sliderWSC = {
      value: angular.isDefined(this._selectedCrop?.TolWet) ? this._selectedCrop.TolWet : 0,
      options: {
        id: 'sliderWSC',
        floor: 0,
        ceil: 2,
        disabled: this.readonly,
        showTicksValues: true,
        hideLimitLabels: true,
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this.setDirtyFormObject('TolWet');
          if (this._selectedCrop.TolWet != modelValue) {
            this._selectedCrop.TolWet = modelValue;
          }
        },
        translate: this._groupSettingService.translateTolerance,
        getPointerColor: (value) => {
          const result = this.getColorIndicator(value);
          return result;
        },
      },
    };

    this.sliderDSC = {
      value:
        angular.isDefined(this._selectedCrop) &&
        angular.isDefined(this._selectedCrop.TolDry) &&
        angular.isDefined(this._selectedCrop.TolDry)
          ? this._selectedCrop.TolDry
          : 0,
      options: {
        id: 'sliderDSC',
        floor: 0,
        ceil: 2,
        disabled: this.readonly,
        showTicksValues: true,
        hideLimitLabels: true,
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this.setDirtyFormObject('TolDry');
          if (this._selectedCrop.TolDry != modelValue) {
            this._selectedCrop.TolDry = modelValue;
          }
        },
        translate: this._groupSettingService.translateTolerance,
        getPointerColor: (value) => {
          const result = this.getColorIndicator(value);
          return result;
        },
      },
    };

    this.sliderLAT = {
      value:
        angular.isDefined(this._selectedCrop) &&
        angular.isDefined(this._selectedCrop.TolCold) &&
        angular.isDefined(this._selectedCrop.TolCold)
          ? this._selectedCrop.TolCold
          : 0,
      options: {
        id: 'sliderLAT',
        floor: 0,
        ceil: 2,
        showTicksValues: true,
        hideLimitLabels: true,
        disabled: this.readonly,
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this.setDirtyFormObject('TolCold');
          if (this._selectedCrop.TolCold != modelValue) {
            this._selectedCrop.TolCold = modelValue;
          }
        },
        translate: this._groupSettingService.translateTolerance,
        getPointerColor: (value) => {
          const result = this.getColorIndicator(value);
          return result;
        },
      },
    };

    this.sliderHAT = {
      value:
        angular.isDefined(this._selectedCrop) &&
        angular.isDefined(this._selectedCrop.TolHeat) &&
        angular.isDefined(this._selectedCrop.TolHeat)
          ? this._selectedCrop.TolHeat
          : 0,
      options: {
        id: 'sliderHAT',
        floor: 0,
        ceil: 2,
        showTicksValues: true,
        hideLimitLabels: true,
        disabled: this.readonly,
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this.setDirtyFormObject('TolHeat');
          if (this._selectedCrop.TolHeat != modelValue) {
            this._selectedCrop.TolHeat = modelValue;
          }
        },
        translate: this._groupSettingService.translateTolerance,
        getPointerColor: (value) => {
          const result = this.getColorIndicator(value);
          return result;
        },
      },
    };
  }

  public GoToCrop(crop: Swan.Crop): void {
    this._state.go('app.crops.coeff.detail', { cropId: crop.Id });
  }
}

angular.module('app.crops').controller('CoefficientDetailController', CoefficientDetailController);
