import * as angular from 'angular';
import * as moment from 'moment';
import { Asset } from 'src/app/_DBContext/Asset';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { CropService } from '@services/crop.service';
import { FetchDataService } from '@services/fetch-data.service';
import { BaseController } from 'src/app/base.controller';

export class RolloutCropDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _accountService: AccountsService;
  private _cropService: CropService;
  private _fetchDataService: FetchDataService;
  private _languageService: LanguageService;

  public cropRolloutForm: angular.IFormController;

  private cropId: number;

  public assets: Asset[];
  public filteredAssets: Asset[];
  public selectedAssets: number[];
  public assetsCheckedStatus: Map<number, boolean>;
  public assetFilter: string;
  public years: number[];
  public total = 0;
  public amount: number;

  public fromDate: Date;
  public toDate: Date;
  public cropStartDate: Date;
  public cropEndDate: Date;
  public year: number;

  public rolloutWorkingDepth: boolean;
  public type: string;
  public selectAllState: boolean = false;
  public revertToFallow: boolean = false;
  public fallowCropKC: number = 0;
  public cropGrowthPhases: Swan.CropCoefficient[];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    CropService: CropService,
    FetchDataService: FetchDataService,
    LanguageService: LanguageService,

    PermissionService: PermissionService,
    cropId: number,
    type: string,
    cropDuration: number,
    cropStartDate: Date,
    cropGrowthPhases: Swan.CropCoefficient[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._accountService = AccountsService;
    this._cropService = CropService;
    this._fetchDataService = FetchDataService;
    this._languageService = LanguageService;

    this.cropId = cropId;
    this.type = type;
    this.cropStartDate = new Date(cropStartDate.getFullYear(), cropStartDate.getMonth(), cropStartDate.getDate());
    this.cropEndDate = new Date(cropStartDate.getFullYear(), cropStartDate.getMonth(), cropStartDate.getDate()).addDays(
      cropDuration - 1,
    );
    this.cropGrowthPhases = cropGrowthPhases;
  }

  $onInit() {
    //build the year array
    this.years = new Array<number>(5).fill(2).map((v, i) => {
      return new Date().getFullYear() - (i - v);
    });

    this.fromDate = null;
    this.toDate = null;

    this.rolloutWorkingDepth = false;
    this.fetchData();
    this.selectedAssets = new Array<number>();
    this.amount = 0;
  }

  private fetchData() {
    this._accountService.getSystemAccountById(this.accountId).then((account) => {
      if (account.fallowCropId != null) {
        this._cropService.getDetailCrop(account.fallowCropId).then((crop) => {
          if (crop.CropCoefficients.length) {
            this.fallowCropKC = crop.CropCoefficients[0].WaterCoefficient;
          }
        });
      }
    });
    if (this.type == 'site') {
      this._fetchDataService.fetchAccountSites(this.accountId).then(
        (data) => {
          this.assets = (data as Asset[]).filter((s) => s.Status === 'Active');
          this.assetsCheckedStatus = new Map<number, boolean>();
          for (let i = 0; i < this.assets.length; i++) {
            this.assetsCheckedStatus.set(this.assets[i].AssetId, false);
          }
          this.total = this.assets.length;
        },
        () => {
          this._languageService.whoops();
          this._mdDialog.hide();
        },
      );
    } else {
      this._fetchDataService.fetchAccountCMUs(this.accountId).then(
        (data) => {
          this.assets = (data as Asset[]).filter((s) => s.Status === 'Active');
          this.assetsCheckedStatus = new Map<number, boolean>();
          for (let i = 0; i < this.assets.length; i++) {
            this.assetsCheckedStatus.set(this.assets[i].AssetId, false);
          }
          this.total = this.assets.length;
        },
        () => {
          this._languageService.whoops();
          this._mdDialog.hide();
        },
      );
    }
  }

  public setTouched(ctl) {
    if (this.cropRolloutForm != null && this.cropRolloutForm[ctl] != null) this.cropRolloutForm[ctl].$setTouched();
  }

  public changeStatus(currentAsset: number) {
    const statement = this.assetsCheckedStatus.get(currentAsset);
    if (statement) {
      this.assetsCheckedStatus.set(currentAsset, false);
      this.amount = this.selectedAssets.length - 1;
    } else {
      this.assetsCheckedStatus.set(currentAsset, true);
      this.amount = this.selectedAssets.length + 1;
    }
  }

  public selectAll() {
    // note the filter in this method is separate from the html filter
    // change the html as well if the filter in this method is changed
    if (this.assetFilter == null) {
      this.filteredAssets = this.assets;
    } else {
      this.filteredAssets = this.assets.filter((a) => a.Name.toLowerCase().includes(this.assetFilter.toLowerCase()));
    }
    if (this.selectAllState) {
      for (let i = 0; i < this.filteredAssets.length; i++) {
        this.assetsCheckedStatus.set(this.filteredAssets[i].AssetId, true);
      }
    } else {
      for (let i = 0; i < this.filteredAssets.length; i++) {
        this.assetsCheckedStatus.set(this.filteredAssets[i].AssetId, false);
      }
    }
    this.selectedAssets = [];
    this.assetsCheckedStatus.forEach((value, key) => {
      if (value) {
        this.selectedAssets.push(key);
      }
    });
    this.amount = this.selectedAssets.length;
  }

  public rolloutButtonEnabled(): boolean {
    return this.fromDate != null && this.toDate != null && this.assetsChecked();
  }

  public assetsChecked(): boolean {
    if (this.selectedAssets == null) {
      return false;
    }
    return !!this.selectedAssets.length;
  }

  public getAssetIds(): number[] {
    if (this.type == 'site') {
      return this.selectedAssets;
    }
    if (this.type == 'group') {
      return this.assets
        .filter((a) => this.selectedAssets.indexOf(a.AssetId) >= 0)
        .map((a) => a.ChildAssets.map((c) => c.ChildAssetId))
        .reduce((a, v) => a.concat(v), []);
    }
    return [];
  }

  public rolloutToSites() {
    let fromDate = moment(this.fromDate).toDate();
    let toDate = moment(this.toDate).toDate();
    fromDate = new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()));
    toDate = new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()));
    const rolloutDto = {
      cropId: this.cropId,
      cropStartDate: new Date(Date.UTC(this.cropStartDate.getFullYear(), this.cropStartDate.getMonth(), this.cropStartDate.getDate())),
      cropGrowthPhases: this.cropGrowthPhases,
      assetIds: this.selectedAssets,
      assetType: this.type,
      fromDate: fromDate,
      toDate: toDate,
      targetYear: this.year,
      rolloutWorkingDepth: this.rolloutWorkingDepth,
      revertToFallow: this.revertToFallow,
      fallowCropKc: this.fallowCropKC,
    } as fuse.SiteCropKcRolloutDto;
    this._languageService.info('CROPS.ROLLOUT_DIALOG.APPLYING_CROP');
    this._cropService
      .rolloutCrop(rolloutDto)
      .then(
        () => {
          this._languageService.success('CROPS.ROLLOUT_DIALOG.APPLY_CROP_SUCCESS');
        },
        (e) => {
          this._languageService.handleError(e, 'CROPS.ROLLOUT_DIALOG.APPLY_CROP_FAILED');
        },
      )
      .catch((e) => {
        this._languageService.handleError(e, 'CROPS.ROLLOUT_DIALOG.APPLY_CROP_FAILED');
      });

    this.closeDialog();
  }

  public closeDialog() {
    this._mdDialog.hide({ dataRefreshRequired: false });
  }

  public onYearSelected() {
    const elements = document.getElementsByClassName('yearsList');
    if (elements.length) {
      elements[0].classList.remove('yearsList');
    }
  }
}

angular.module('app.crops').controller('RolloutCropDialogController', RolloutCropDialogController);
