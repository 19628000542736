import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

export class EditPricesDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  public title: string;

  public cropPrices: fuse.cropPricesDto;
  public cropMetricsList: fuse.cropMetricsDto[];
  public cropMetricsId: number;

  public selectedIds: number[];
  public unitId: number;
  public editMode: boolean;
  public currencyCode = null as string;
  public entityList: EntityList<any>;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    cropMetricsList: EntityList<fuse.cropMetricsDto>,
    entityList: EntityList<any>,
    cropPrices: fuse.cropPricesDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.entityList = entityList;
    this.cropPrices = angular.copy(cropPrices); // copied so can revert changes easily
    this.cropMetricsList = cropMetricsList.entities;

    this.currencyCode = this.account.currencyCode;
    if (cropPrices == null) {
      // Add mode
      this.title = this._languageService.instant('CROPS.ADD_PRICES');
      this.cropMetricsList = cropMetricsList.getActiveOnly();
      this.editMode = false;
      this.cropPrices = {
        name: '',
        status: 'Active',
      } as fuse.cropPricesDto;
    } else {
      // Edit mode
      this.title = this._languageService.instant('CROPS.EDIT_PRICES');
      this.editMode = true;
      this.cropMetricsId = this.cropPrices.cropMetrics.id;
      this.checkForNewGrades();
      this.setUnit();
    }
  }

  public setUnit() {
    // Unit must be in /weight, not just 'weight'
    this.cropPrices['uom'] = new uomUnit(this.cropPrices.cropMetrics.unit).getInverse();
    this.cropPrices['uom'].decimalPlaces = 2; // Decimal place override for currency
  }

  public saveChanges() {
    this._mdDialog.hide(this.cropPrices);
  }

  public updateCropMetrics() {
    const cropMetrics = this.cropMetricsList.find((cm) => cm.id == this.cropMetricsId);

    this.cropPrices.cropMetrics = cropMetrics;
    this.cropPrices.gradePrices = cropMetrics.grades.map((grade) => {
      return { grade: grade, price: 0 } as any;
    });
    this.setUnit();
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public checkForNewGrades() {
    const cropMetrics = this.cropMetricsList.find((cm) => cm.id == this.cropMetricsId);

    // Can happen if new grade has been added to the parent crop metrics object since prices were saved
    let newGrades = cropMetrics.grades.length - this.cropPrices.gradePrices.length;
    while (newGrades > 0) {
      const grade = cropMetrics.grades[this.cropPrices.gradePrices.length];
      this.cropPrices.gradePrices.push({ grade: grade, price: 0 } as any);
      newGrades--;
    }
  }
}

angular.module('app.crops').controller('EditPricesDialogController', EditPricesDialogController);
