// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#crop-cycle-dialog {
  width: 1000px;
}
#crop-cycle-dialog md-dialog-content {
  padding: 12px;
}
#crop-cycle-dialog #errors-header {
  background-color: #666;
  color: #fff;
  padding: 4px;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/crops/cycles/dialogs/crop-cycle-dialog.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,aAAA;AAER;AAAI;EACI,sBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,MAAA;EACA,WAAA;EACA,WAAA;AAER","sourcesContent":["#crop-cycle-dialog {\r\n    width: 1000px;\r\n    md-dialog-content{\r\n        padding: 12px;\r\n    }\r\n    #errors-header {\r\n        background-color: #666;\r\n        color: #fff;\r\n        padding: 4px;\r\n        position: sticky;\r\n        top: 0;\r\n        z-index: 10;\r\n        width: 100%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
