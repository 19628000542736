import * as angular from 'angular';
import { CoefficientDetailController } from './coeff-detail.controller';
import { CropCoefficientsController } from './coeff.controller';
import { CropCyclesController } from './cycles/cycles.controller';
import { CropMetricsController } from './metrics.controller';
import { CropPricesController } from './prices.controller';
import { CropTargetsController } from './targets.controller';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';

const cropsConfig = (
  $stateProvider: angular.ui.IStateProvider,
) => {
  $stateProvider.state('app.crops', {
    abstract: true,
    url: '/crops',
  });

  $stateProvider.state('app.crops.coeff', {
    url: '/coeff',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/crops/coeff.html',
        controller: CropCoefficientsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.AdminCropLibraryView },
  });

  $stateProvider.state('app.crops.coeff.detail', {
    url: '/:cropId',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/crops/coeff-detail.html',
        controller: CoefficientDetailController,
        controllerAs: 'vm',
      },
    },
  });

  $stateProvider.state('app.crops.cycles', {
    url: '/cycles',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/crops/cycles/cycles.html',
        controller: CropCyclesController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.CropCyclesView },
  });

  $stateProvider.state('app.crops.metrics', {
    url: '/metrics',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/crops/metrics.html',
        controller: CropMetricsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.CropMetricsView },
  });

  $stateProvider.state('app.crops.prices', {
    url: '/prices',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/crops/prices.html',
        controller: CropPricesController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.CropMetricsView },
  });

  $stateProvider.state('app.crops.targets', {
    url: '/targets',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/crops/targets.html',
        controller: CropTargetsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.CropMetricsView },
  });
}

angular.module('app.crops').config(['$stateProvider', cropsConfig]);
