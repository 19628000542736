import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';

export class CropCycleDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  public title: string;
  public allActiveCycles: fuse.siteCropCycle[];
  public accountInfo: fuse.accountCropInfo;
  public isAdd = false;
  public step = 0;
  public cropCycleOption = {} as fuse.cropCycleOptionDto;
  public startDate: Date;
  public endDate: Date;
  public cropMetricId = null as number;
  public cropPriceId = null as number;
  public cropTargetId = null as number;
  private cyclesForEdit: fuse.siteCropCycle[];
  private otherCycles = {};
  public hasResults: boolean = false;
  private dateFormat = 'dd MMM yyyy';

  public selectedSites: fuse.siteProfileDto[] = [];
  public sitesStr: string;
  public autoSetDates = true;
  public cropMetrics: fuse.cropMetricsStruct | undefined;

  public isOptionValid = true;
  public overlapErrs = [] as string[];
  public overlapHeader: string;
  public overlapFormat: string;
  public harvestErrHeader = 'CROPS.CYCLES.HARVEST_WARNING';
  public harvestErrs: any[];
  public hasChanges: boolean;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    cyclesForEdit: fuse.siteCropCycle[],
    accountInfo: fuse.accountCropInfo,
    allActiveCycles: fuse.siteCropCycle[],
    startDate: Date,
    endDate: Date,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.allActiveCycles = allActiveCycles;
    this.accountInfo = accountInfo;
    this.startDate = startDate;
    this.endDate = endDate;
    this.hasResults = cyclesForEdit.some((c) => !!c.harvests.length);

    this.title = 'CROPS.CYCLES.EDIT_CROP_CYCLES';
    this.isAdd = false;
    this.hasChanges = false;
    if (!cyclesForEdit.length) {
      //add new cycle
      this.step = 0;
      this.isAdd = true;
      this.title = 'CROPS.CYCLES.ADD_CROP_CYCLES';
      this.cyclesForEdit = [];
      this.hasChanges = true;
    } else {
      if (cyclesForEdit.length == 1) {
        //edit single cycle
        this.step = 1;
        this.cropMetricId = cyclesForEdit[0].cropMetrics.id;
        this.cropPriceId = cyclesForEdit[0].cropPriceId;
        this.cropTargetId = cyclesForEdit[0].cropTargetId;
        this.selectedSites = cyclesForEdit.map((a) => {
          return { siteId: a.siteId, name: a.siteName } as fuse.siteProfileDto;
        });
        this.setSitesStr();
      } else {
        //edit multiple cycles
        this.step = 1;
        this.selectedSites = cyclesForEdit
          .filter((v, i, a) => a.indexOf(v) === i)
          .map((a) => {
            return { siteId: a.siteId, name: a.siteName } as fuse.siteProfileDto;
          }); //filter removes duplicates, though dupes should be impossible anyway
      }
      this.cyclesForEdit = cyclesForEdit;
      // Generate list of all other cycles for each site in edit set, so we can make sure dates don't overlap
      cyclesForEdit?.forEach((cycle) => {
        this.otherCycles[cycle.siteId] = this.allActiveCycles.filter(
          (c) => c.siteId == cycle.siteId && c.cropCycleId != cycle.cropCycleId,
        );
      });
      this.autoSetDates = false;
      this.setCropMetric();
    }
  }

  // Sets default cycle start/end dates that overlap no existing cycle for any selected site
  // Default cycle set to start 1 day after last cycle for any selected site, and runs for 1 year
  public setStartDate() {
    if (!this.autoSetDates) return;

    const siteIds = this.selectedSites.map((s) => s.siteId);
    const existingCycles = this.allActiveCycles.filter((cy) => siteIds.some((id) => id == cy.siteId));
    if (existingCycles.length) {
      const lastEnd = existingCycles.map((c) => c.endDate).reduce((n, c) => (n > c ? n : c), new Date(0)); // find last end date of any cycle
      this.startDate = lastEnd.clone().addDays(1); //new Date(lastEnd.valueOf()+864E5); // add one day to last cycle end date
      this.endDate = lastEnd.clone().addYears(1);
    }
  }

  public closeDialog() {
    this._mdDialog.hide(null);
  }

  public onSelectedSitesChanged(selectedSites: fuse.siteProfileDto[]): void {
    this.selectedSites = selectedSites;
  }

  public onOptionChanged(option: fuse.cropCycleOptionDto, datesChanged: boolean = false): void {
    this.hasChanges = true;
    this.cropCycleOption = option;
    (option.startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(option.startDate)),
      (option.endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(option.endDate)),
      (this.startDate = option.startDate);
    this.endDate = option.endDate;
    this.autoSetDates = this.autoSetDates && datesChanged;
    this.validateCropCycleOption();
  }

  public back(): void {
    this.step--;
  }

  public next(): void {
    this.cyclesForEdit = this.selectedSites.map((site) => ({ siteId: site.siteId }) as fuse.siteCropCycle);
    this.cyclesForEdit.forEach((cycle) => {
      this.otherCycles[cycle.siteId] = this.allActiveCycles.filter(
        (c) => c.siteId == cycle.siteId && c.cropCycleId != cycle.cropCycleId,
      );
    });
    this.setStartDate();
    this.validateCropCycleOption();
    this.step++;
    this.setCropMetric();
    this.setSitesStr();
  }

  public copyCycleDetails(cycle: fuse.siteCropCycle, options: fuse.cropCycleOptionDto) {
    cycle.cropMetricId = options.cropMetricId;
    cycle.cropPriceId = options.cropPriceId;
    cycle.cropTargetId = options.cropTargetId;
    cycle.startDayNumber = options.startDayNumber;
    cycle.endDayNumber = options.endDayNumber;
  }

  public save() {
    this.cyclesForEdit.forEach((cycle) => this.copyCycleDetails(cycle, this.cropCycleOption));
    this._http.post(CommonHelper.getApiUrl('crops/updateCropCycles'), this.cyclesForEdit).then(
      (result) => {
        this._mdDialog.hide({ startDate: this.startDate, endDate: this.endDate });
        this._languageService.showSaveSuccess();
      },
      (err) => {
        this._languageService.error('COMMON.CHANGES_NOT_SAVED');
      },
    );
  }

  private validateCropCycleOption() {
    this.overlapErrs = [];
    this.harvestErrs = [];
    this.isOptionValid = !!this.cropCycleOption.cropMetricId;

    if (this.startDate == null || this.endDate == null) {
      this.isOptionValid = false;
      return;
    }
    this.selectedSites.forEach((site) => {
      const siteId = site.siteId;
      const otherCycles = this.otherCycles[siteId];
      if (otherCycles == null) return;
      const invalidCycles = otherCycles.filter(
        (o) => this.cropCycleOption.startDate <= o.endDate && this.cropCycleOption.endDate >= o.startDate,
      );
      invalidCycles.forEach((inv) => {
        const params = {
          site: inv.siteName,
          from: inv.startDate.toString(this.dateFormat),
          to: inv.endDate.toString(this.dateFormat),
        };
        this.overlapErrs.push(this._languageService.instant(this.overlapFormat, params));
        this.isOptionValid = false;
      });

      const existingCycle = this.cyclesForEdit.find((cy) => cy.siteId == siteId);
      if (existingCycle != null) {
        existingCycle.harvests?.forEach((harvest) => {
          if (harvest.date < this.startDate || harvest.date > this.endDate) {
            this.isOptionValid = false;
            this.harvestErrs.push(harvest.date.toString(this.dateFormat));
          }
        });
      }
    });
  }

  $onChanges(changes) {
    if (changes.accountInfo?.currentValue) {
      this.changeOption();
    }
  }

  public setCropMetric() {
    this.cropMetrics = this.accountInfo.cropMetrics.find((cm) => cm.id == this.cropMetricId);
  }

  public setSitesStr() {
    this.sitesStr = this.selectedSites
      .slice(0, 20)
      .map((s) => s.name)
      .join(', ');
    if (this.selectedSites.length > 20) this.sitesStr += ' ...';

    this.overlapHeader = 'CROPS.CYCLES.OVERLAP_SINGLE';
    this.overlapFormat = 'COMMON.DATE_TO_DATE';
    if (this.selectedSites.length > 1) {
      this.overlapHeader = 'CROPS.CYCLES.OVERLAP_MULTI';
      this.overlapFormat = 'CROPS.CYCLES.INVALID_CYCLE';
    }
  }

  public changeMetric() {
    this.cropPriceId = null;
    this.cropTargetId = null;
    this.changeOption();
    this.setCropMetric();
  }

  public changeOption(datesChanged: boolean = false) {
    const option = {
      startDate: this.startDate,
      endDate: this.endDate,
      cropMetricId: this.cropMetricId,
      cropPriceId: this.cropPriceId,
      cropTargetId: this.cropTargetId,
    } as fuse.cropCycleOptionDto;
    this.onOptionChanged(option, datesChanged);
  }

  public changeDate() {
    this.changeOption(true);
  }
}

angular.module('app.crops').controller('CropCycleDialogController', CropCycleDialogController);
