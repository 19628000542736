import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
export class MainDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
  }

  public saveChanges() {
    this._mdDialog.hide({ dialogValue: 'save' });
  }

  public cancelChanges() {
    this._mdDialog.hide({ dialogValue: 'discard' });
  }

  public keepEditing() {
    this._mdDialog.hide({ dialogValue: 'keepEditing' });
  }
}

angular.module('fuse').controller('MainDialogController', MainDialogController);
